import React from "react";
import PropTypes from "prop-types";
import i18n from "../../i18n";
import SimpleTab from "./SimpleTab";
import * as api from "../../utils/api";
import * as photolabUtils from "../../utils/photolab";

const FETCH_INTERVAL = 2000;

class VectorTab extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isRefreshView: false,
      task: null,
    };

    this.fetchTimer = null;
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
  }

  handleRefreshButtonClick = () => {
    const nextState = {isRefreshView: true};

    if (this.props.photo.vector_preview_task) {
      nextState.task = this.props.photo.vector_preview_task;
    } else {
      this.fetchTask();
    }

    this.setState(nextState);
  };

  fetchTask = () => {
    api.fetchPhotoVectorPreviewTask(this.props.photo.id)
      .then((res) => this.handleFetchTask(res.task))
      .catch((err) => console.error(err));
  };

  handleFetchTask = (task) => {
    if (photolabUtils.taskIsProcessing(task)) {
      this.fetchTimer = setTimeout(this.fetchTask, FETCH_INTERVAL);
    }

    this.setState({task});
  };

  handleSelectTemplate = (templateId) => {
    this.setState({
      isRefreshView: false,
      task: null,
    }, () => this.props.onTemplateSelect(templateId));
  };

  render() {
    if (this.state.isRefreshView) {
      if (!this.state.task || photolabUtils.taskIsProcessing(this.state.task)) {
        return <div className="creative-holder">
          <div className="creative-holder-placeholder" />
          <div className="loader-video">
            <span className="item-loader first-item-loader" />
            <span className="item-loader second-item-loader" />
            <span className="item-loader third-item-loader" />
          </div>
        </div>;
      }

      if (photolabUtils.taskIsProcessed(this.state.task)) {
        const ts = Object.keys(this.state.task.response.results);

        return <div className="choose-page">
          <div className="container">
            <h2>{i18n.t("vector_tab_refresh__title")}</h2>
            <p>{i18n.t("vector_tab_refresh__subtitle")}</p>
          </div>
          <div className="choose-items">
            {ts.map((templateId) => <div className="choose-item-container" key={templateId}>
              <div className="choose-item">
                <img
                  src={this.state.task.response.results[templateId]}
                  width={100}
                  alt={templateId}
                  onClick={() => this.handleSelectTemplate(templateId)} />
              </div>
            </div>)}
          </div>
        </div>;
      }

      return <div>task failed</div>;
    }

    const tabProps = {...this.props};
    tabProps.onRefreshButtonClick = this.handleRefreshButtonClick;

    return <SimpleTab {...tabProps} />;
  }
}

VectorTab.propTypes = {
  group: PropTypes.string.isRequired,
  photo: PropTypes.object.isRequired,
  creatives: PropTypes.array.isRequired,
  withHD: PropTypes.bool,
  isGuest: PropTypes.bool,
  canBeRefreshed: PropTypes.bool,
  onImageLoaded: PropTypes.func.isRequired,
  onHideWatermarkButtonClick: PropTypes.func.isRequired,
  onShowWatermarkButtonClick: PropTypes.func.isRequired,
  onDownloadButtonClick: PropTypes.func.isRequired,
  onRefreshButtonClick: PropTypes.func.isRequired,
  onTemplateSelect: PropTypes.func.isRequired,
};

export default VectorTab;