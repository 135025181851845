import React from "react";
import i18n from "../i18n";
import routes from "../routes";
import {Link} from "react-router-dom";
import {PHOTO_LAB_LINK} from "../utils/constants";
import {webviewOpenBrowser} from "../utils/webview";
import {ExternalUrl} from "../pages/IndexPage";

export default class HeaderLabs extends React.Component {

  handlePhotoLabClick = (e) => {
    if (window.clientConfig.isWebview) {
      e.preventDefault();
      webviewOpenBrowser(PHOTO_LAB_LINK);
    }
  };

  render() {
    if (this.props.hidden) {
      return null;
    }

    return <header className="header">
      <div className="header-main-content">
        <Link to={routes.LABS_INDEX} className="logo">toonme labs</Link>
        <ExternalUrl url={PHOTO_LAB_LINK} children={i18n.t("btn_photo_lab")} className="btn-photo-lab" onClick={this.handlePhotoLabClick} />
      </div>
      <div className="header-other-content">
        <Link to={routes.INDEX}>toonme classic</Link>
      </div>
    </header>;
  }
}
