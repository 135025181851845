module.exports = {
  "strings": {
    "try_another_photo": "Probar otra foto",

    "landing__title_part_1": "¡Viva!",
    "landing__title_part_2": "Es hora de vivir tu sueño de dibujos animados, una y otra vez.",
    "landing__subtitle": "Solo tienes que confiar tu foto a la IA 😜",
    "landing__select_photo_button": "Elige la foto",
    "landing__get_the_app_button": "Llévate la aplicación",
    "loading__uploading": "Cargando...",

    "landing__title_labs": "ToonMe.com LABS",
    "landing__subtitle_labs": "Estás en un lugar experimental especial ahora. Para los estilos originales, vuelve a la versión principal que aparece a continuación.",
    "landing__labs_back_button": "Volver a ToonMe Classic",
    "landing__labs_choice": "O prueba nuestros estilos experimentales en <span>ToonMe&nbsp;LABS</span>",

    "result__labs_tab_title": "Welcome to ToonMe LABS",
    "result__labs_tab_message": "¡Estamos experimentando con nuevos estilos y te dejamos que les eches un vistazo! Vuelve a los estilos originales en cualquier momento.",
    "result__labs_tab_button": "Vamos allá",
    "result__tab_classic_title": "¿Estás disfrutando de los efectos experimentales?",
    "result__tab_classic_info": "Quédate aquí o vuelve a la versión principal de ToonMe",
    "result__tab_classic_btn": "Volver a ToonMe Classic",

    "processing_text_1": "Amarilleando tu piel... 🖌️",
    "processing_text_2": "Haciendo una caricatura de tus rasgos faciales...",
    "processing_text_3": "Pareces una princesa Disney. Es broma. ¿O no?... 🙈",
    "processing_text_4": "Este es el lugar donde los sueños se convierten en una CARICATURA 🦄",
    "processing_text_5": "¡Optimizando la caricatura de tu foto!",
    "processing_text_6": "¡Caricaturización en curso!",
    "processing_text_7": "¡A Walt Disney le hubiera gustado! ✨",

    "collage__select_photo": "Prueba con tu foto",
    "collage__download": "Descargar en HD",
    "collage__refresh": "Actualizar",
    "collage__more_styles": "Más estilos",

    "download_modal__title": "¡No te detengas aquí!",
    "download_modal__message": "¿Qué se siente al ser una estrella de las caricaturas? ¡Comparte tu momento de fama en Instagram publicando el resultado que más te guste!",
    "download_modal__message_wait": "Creando el resultado en HD…",
    "download_modal__message_ready": "La versión en HD está lista, la descarga ha empezado…",
    "download_modal__dismiss_button": "Conseguido",
    "download_modal__download_button": "Descargar de nuevo",

    "error__error": "Error",

    "internal_error": "Ha ocurrido un error...",
    "error_overload_webview": "Lo lamentamos enormemente, estamos teniendo altas cargas en el servidor debido a las elevadas descargas de la aplicación. Por favor, prueba a usar la pestaña Toon Effects o vuelve un poco más tarde. Estamos haciendo un gran esfuerzo para solucionarlo todo en breve.",
    "error_overload_web": "Lo lamentamos enormemente, estamos teniendo altas cargas en el servidor debido a las elevadas descargas de la aplicación. Por favor, vuelve un poco más tarde. Estamos haciendo un gran esfuerzo para solucionarlo todo en breve.",

    "rateapp_dialog__title": "¿Te gusta ToonMe?",
    "rateapp_dialog__message-bold": "¡Dale estrellas a la aplicación y ayuda a otros usuarios a descubrirla y disfrutarla también!",
    "rateapp_dialog__message": "P. D.: también nos ayudará a recuperar las puntuaciones, afectadas por los problemas del servidor después de que ToonMe se hiciera viral. 🙏",
    "rateapp_dialog__rate_button": "¡Puntúanos!",
    "rateapp_dialog__skip_button": "Saltar",

    "btn_store__title":"<span class=\"btn-store-title\">Descárgala en la</span><span class=\"btn-store-name\">{{market}}</span>",
    "btn_store__ios":"App Store",
    "btn_store__android":"Google Play",
    "btn_cancel": "Cancela y guarda en calidad estándar",
    "btn_clone": "Beware of cheap clone!",
    "btn_contacts_form": "Contáctanos",
    "btn_back": "back",

    "vector_tab_refresh__title": "¡Ya casi está!",
    "vector_tab_refresh__subtitle": "Elige un retrato",

    "updated_at": "Actualizado con nuevos efectos el <span class=\"updated-at-date\">{{date}}</span>",

    "warning_clones": "¡AVISO!",
    "warning_clones_link": "CUIDADO CON LAS COPIAS BARATAS",

    "snap_dialog__title": "¿Antes y después o solo una CARICATURA?",
    "snap_dialog__message": "¡Elige cómo compartirla en Snapchap!",
    "snap_dialog__share_button": "Compartir",
    "snap_dialog__back_button": "Volver",

    "title_contacts_form": "De los creadores de Photo Lab, la principal aplicación de fotografía de iOS y Android del mundo, con 260 millones de descargas y sigue la cuenta...",

    "toggle_watermark_tooltip__text": "Tap en el logo para removerlo",
    "toggle_watermark_on": "Con logo",
    "toggle_watermark_off": "Sin logo",

    "remove_logo_modal__title": "Mantener el logo o no mantenerlo…",
    "remove_logo_modal__message": "Hola, ¿sabías que el logo es una parte esencial del diseño de este efecto? Nos gustaría que lo mantuvieras.",
    "remove_logo_modal__btn_cancel": "Mantener el logo",
    "remove_logo_modal__btn_remove": "Eliminarlo de todas maneras",

    // v2
    "landing__toonme_on_instagram": "#ToonMe en Instagram",
  },
};
