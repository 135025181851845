import React from 'react';
import i18n from "../../i18n";
import FileChooseButton from "../../components/FileChooseButton";
import {Link} from "react-router-dom";
import routes from "../../routes";
import {ExternalUrl} from "../IndexPage";
import {PHOTO_LAB_LINK} from "../../utils/constants";
import AppContext from "../../contexts/AppContext";
import * as creativeUtils from "../../utils/creative";
import ResultPage from "../result/ResultPage";
import SimpleTab from "./SimpleTab";
import VectorTab from "./VectorTab";
import LabsTab from "../result/LabsTab";
import ClassicTab from "./ClassicTab";
import DownloadModal from "./DownloadModal";
import RemoveLogoModal from "./RemoveLogoModal";

export default class ResultPageV3 extends ResultPage {

  showDownloadModal = (creative, withHD) => {
    this.context.pushModal(<DownloadModal
      key="ResultPageV3-DownloadModal"
      site={this.props.site}
      history={this.props.history}
      tab={this.state.tab}
      photo={this.state.photo}
      creative={creative}
      withHD={withHD}
      downloadHandler={this.handleDownload}
      shareHandler={this.handleShare}
    />);
  };

  showRemoveLogoModal = () => {
    this.context.pushModal(<RemoveLogoModal
      key="ResultPageV3-RemoveLogoModal"
      onShowButtonClick={this.handleShowWatermak}
      onHideButtonClick={this.handleHideWatermak}
    />);
  };

  renderTabContainer = (commonTabSettings) => {
    commonTabSettings.group = this.state.tab;

    switch (this.state.tab) {
      case creativeUtils.creativeGroups.BARBIE:
        return <SimpleTab {...commonTabSettings} canBeRefreshed={false} />;
      case creativeUtils.creativeGroups.CARTOON:
        return <SimpleTab {...commonTabSettings} />;
      case creativeUtils.creativeGroups.CARTOON_ANIM:
        return <SimpleTab {...commonTabSettings} canBeRefreshed={false} />;
      case creativeUtils.creativeGroups.T4532:
        return <SimpleTab {...commonTabSettings} />;
      case creativeUtils.creativeGroups.CARICATURE:
      case creativeUtils.creativeGroups.CARICATURE_2:
      case creativeUtils.creativeGroups.CARICATURE_3:
        return <SimpleTab {...commonTabSettings} withHD={this.state.tab === creativeUtils.creativeGroups.CARICATURE_3} />;
      case creativeUtils.creativeGroups.SIMPSONS:
        return <SimpleTab {...commonTabSettings} />;
      case creativeUtils.creativeGroups.CUPID21_1:
        return <SimpleTab {...commonTabSettings} canBeRefreshed={false} />;
      case creativeUtils.creativeGroups.CUPID21_2:
        return <SimpleTab {...commonTabSettings} />;
      case creativeUtils.creativeGroups.DISNEY:
        return <SimpleTab {...commonTabSettings} withHD={true} />;
      case creativeUtils.creativeGroups.DISNEY_2D:
        return <SimpleTab {...commonTabSettings} withHD={true} />;
      case creativeUtils.creativeGroups.ARCHER:
        return <SimpleTab {...commonTabSettings} withHD={true} />;
      case creativeUtils.creativeGroups.VECTOR:
        return <VectorTab {...commonTabSettings} withHD={true} onTemplateSelect={this.handleVectorRefreshButtonClick} />;
      case creativeUtils.creativeGroups.COLLAGE:
        return <SimpleTab {...commonTabSettings} canBeRefreshed={false} onRefreshButtonClick={this.handleRefreshCollageButtonClick} />;
      case "labs":
        return <LabsTab onButtonClick={this.handleLabsButtonClick} />;
      case "classic":
        return <ClassicTab onButtonClick={this.handleClassicButtonClick} />;
      default: {
        return <div />;
      }
    }
  };

  renderView = ({isOwner, isGuest, commonTabSettings, showTabs}) => {
    const indexRoute = window.clientConfig.isWebview ? routes.LOVE_INDEX : routes.INDEX;

    return <main className={"collage-page" + (this.props.site === "labs" ? " labs-container" : "")}>
      <div className="container">
        <header className="header" hidden={window.clientConfig.isWebview}>
          <div className="header-main-content">
            <Link to={indexRoute} className="logo">
              toonme&nbsp;cupid
            </Link>
            <ExternalUrl url={PHOTO_LAB_LINK} children={i18n.t("btn_photo_lab")} className="btn-photo-lab" onClick={this.handlePhotoLabClick} />
          </div>
        </header>
      </div>
      <div className="collage-page-content container">
        <div className="tabs-container">
          {showTabs.map((group) => this.renderTab(group))}
        </div>
        {this.renderTabContainer(commonTabSettings)}
      </div>

      <div className="container" hidden={isOwner}>
        <FileChooseButton
          onFileSelected={this.handleFileSelected}
          className="btn-upload-foto"
          children={i18n.t("collage__select_photo")} />
      </div>
    </main>;
  };
}

ResultPageV3.contextType = AppContext;
