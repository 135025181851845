import React from "react";
import PropTypes from "prop-types";
import Modal from "../../components/Modal";
import clientStorage from "../../utils/client-storage";
import {hitEvent, hits, logEvent, userEvents} from "../../utils/log";
import routes from "../../routes";
import * as api from "../../utils/api";
import * as creativeUtils from "../../utils/creative";
import * as webviewUtils from "../../utils/webview";
import {
  SvgCloudLeft,
  SvgCloudRight,
  SvgCloudTop,
  SvgFacebookShareIcon,
  SvgInstagramShareIcon,
  SvgModalBackground, SvgSnapchatShareIcon
} from "../../ui/svg";
import i18n from "../../i18n";
import {APPSTORE_LINK, PLAY_MARKET_LINK} from "../../utils/constants";

const FETCH_INTERVAL = 2000;

const SHARE_PROVIDER_SNAPCHAT = "snapchat";
const SHARE_PROVIDER_FACEBOOK = "facebook";
const SHARE_PROVIDER_INSTAGRAM = "instagram";

class DownloadModal extends Modal {

  constructor(props) {
    super(props);

    this.state = {
      creative: null,
      withHD: props.withHD,
      view: this.isShowRateAppView()
        ? "rate"
        : (this.isShowClonesView() ? "clones" : "download"),
      classCheckFoto: null,
    };

    this.fetchTimer = null;
  }

  componentDidMount() {
    this.fetchCreative();
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
  }

  isShowClonesView = () => {
    if (window.clientConfig.isWeb || window.clientConfig.isWebviewAndroid) {
      return false;
    }

    if (window.clientConfig.lang !== "en") {
      return false;
    }

    const isShowDialog = (Math.random() <= window.appConfig.webview.clonesDialogPercent)
      && (clientStorage.getClonesDialogLastShow() <= Date.now() - 86400000)
      && !clientStorage.getClonesDialogIsAccept()
      && !clientStorage.getClonesDialogIsSkip();

    if (isShowDialog) {
      hitEvent(hits.CLONES_DIALOG_SHOW);
      logEvent(userEvents.CLONES_DIALOG_SHOW);

      clientStorage.setClonesDialogLastShow(Date.now());
    }

    return isShowDialog;
  };

  isShowRateAppView = () => {
    if (window.clientConfig.isWeb || this.props.site === "love") {
      return false;
    }

    const isShowDialog = (Math.random() <= window.appConfig.webview.rateAppDialogPercent)
      && (clientStorage.getRateAppRequestLastShow() <= Date.now() - 86400000)
      && !clientStorage.getRateAppRequestIsRate()
      && !clientStorage.getRateAppRequestIsSkip();

    if (isShowDialog) {
      hitEvent(hits.RATEAPP_DIALOG_SHOW);
      logEvent(userEvents.RATEAPP_DIALOG_SHOW);

      clientStorage.setRateAppRequestLastShow(Date.now());
    }

    return isShowDialog;
  };

  handleGotItButtonClick = () => {
    this.dismiss();
  };

  handleClonesDialogAcceptButtonClick = () => {
    clientStorage.setClonesDialogIsAccept(true);

    hitEvent(hits.CLONES_DIALOG_ACCEPT);
    logEvent(userEvents.CLONES_DIALOG_ACCEPT);

    this.props.history.push(routes.CLONEFIGHTERS);
    this.dismiss();
  };

  handleClonesDialogSkipButtonClick = () => {
    clientStorage.setClonesDialogIsSkip(true);

    hitEvent(hits.CLONES_DIALOG_SKIP);
    logEvent(userEvents.CLONES_DIALOG_SKIP);

    this.dismiss();
  };

  handleRateAppRateButtonClick = () => {
    clientStorage.setRateAppRequestIsRate(true);

    hitEvent(hits.RATEAPP_DIALOG_RATE);
    logEvent(userEvents.RATEAPP_DIALOG_RATE);

    this.setState({view: "download"}, () => {
      this.dismiss();

      if (window.clientConfig.isWebviewAndroid) {
        const vc = parseInt(window.clientConfig.webviewParams.version_code);

        if (vc && vc >= 91) {
          webviewUtils.webviewRateApp((res) => {
            switch (parseInt(res)) {
              case 0: hitEvent(hits.RATEAPP_DIALOG_INAPP_RESPONSE_0); return;
              case 1: hitEvent(hits.RATEAPP_DIALOG_INAPP_RESPONSE_1); return;
              case 2: hitEvent(hits.RATEAPP_DIALOG_INAPP_RESPONSE_2); return;
              case 3: hitEvent(hits.RATEAPP_DIALOG_INAPP_RESPONSE_3); return;
              default:
                return;
            }
          });
        } else {
          webviewUtils.webviewOpenBrowser(PLAY_MARKET_LINK);
        }
      } else {
        webviewUtils.webviewOpenBrowser(APPSTORE_LINK);
      }
    });
  };

  handleRateAppSkipButtonClick = () => {
    clientStorage.setRateAppRequestIsSkip(true);

    hitEvent(hits.RATEAPP_DIALOG_SKIP);
    logEvent(userEvents.RATEAPP_DIALOG_SKIP);

    this.setState({view: "download"});
  };

  fetchCreative = () => {
    if (this.state.withHD && this.props.creative.template.id_hd) {
      api.fetchHDCreative(this.props.photo.id, this.props.creative.id)
        .then((res) => this.handleCreative(res.creative))
        .catch((err) => {
          console.error(err);
        });
    } else {
      /*api.fetchCreative(this.props.photo.id, this.props.creative.id)
        .then((res) => this.handleCreative(res.creative))
        .catch((err) => {
          console.error(err);
        });*/
      this.handleCreative(this.props.creative);
    }
  };

  handleCreative = (creative) => {
    this.setState({creative});

    if (creativeUtils.creativeIsProcessing(creative)) {
      this.fetchTimer = setTimeout(this.fetchCreative, FETCH_INTERVAL);
      return;
    }

    if (creativeUtils.creativeIsProcessed(creative)) {
      this.props.downloadHandler(this.props.creative, creative);
    }
  };

  handleDownload = (e) => {
    this.props.downloadHandler(
      this.props.creative, // SD from props
      this.state.creative  // HD from state
    );
  };

  handleCancelSave = (e) => {
    clearTimeout(this.fetchTimer);

    this.setState({withHD: false}, () => {
      this.handleCreative(this.props.creative);
    });
  };

  handleShareToFacebook = (e) => {
    this.props.shareHandler(SHARE_PROVIDER_FACEBOOK, this.props.creative, this.state.creative);

    webviewUtils.webviewShareFacebook(null, this.state.creative.file.url);
  };

  handleShareToInstagram = (e) => {
    this.props.shareHandler(SHARE_PROVIDER_INSTAGRAM, this.props.creative, this.state.creative);

    webviewUtils.webviewShare({
      providers: "[1]",
      imageUrl: encodeURIComponent(this.state.creative.file.url),
      ig_direct: 1,
      close_after_share: 1,
      no_sound: 1,
    });
  };

  handleShareToSnapchatButtonClick = () => {
    const withoutChoiceGroups = [
      creativeUtils.creativeGroups.COLLAGE,
      creativeUtils.creativeGroups.VECTOR,
    ];

    if (withoutChoiceGroups.indexOf(this.props.creative.group) > -1 || this.props.site === "love") {
      this.shareSnapchat(false);
    } else {
      this.setState({view: "snapchat"});
    }
  };

  shareSnapchat = (isRaw) => {
    this.props.shareHandler(SHARE_PROVIDER_SNAPCHAT, this.props.creative, this.state.creative, {
      is_raw: isRaw
    });

    this.shareSnapchatSticker(isRaw);
    this.setState({
      view: "download",
      classCheckFoto: isRaw ? true : false
    });
  };

  shareSnapchatImage = (isRaw) => {
    const shareUrl = new URL(this.props.photo.urls.share);
    shareUrl.searchParams.set("ref", "snapchat_image");

    const imageUrl = new URL(this.props.photo.urls.share_snapchat_image);
    imageUrl.searchParams.set("creative_id", this.props.creative.id);
    if (isRaw) {
      imageUrl.searchParams.set("raw", "1");
    }

    webviewUtils.webviewShare({
      providers: "[11]",
      caption_text: "",
      attachment_url: encodeURIComponent(shareUrl.toString()),
      imageUrl: encodeURIComponent(imageUrl.toString()),
    });
  };

  shareSnapchatSticker = (isRaw) => {
    const sw = Math.floor(window.screen.width * (window.devicePixelRatio || 1));
    const stickerSize = window.clientConfig.isWebviewIOS
      ? 320
      : Math.floor(sw * 0.95);

    const shareUrl = new URL(this.props.photo.urls.share);
    shareUrl.searchParams.set("ref", "snapchat_sticker" + (isRaw ? "_oa" : ""));

    const stickerUrl = new URL(this.props.photo.urls.share_snapchat_sticker);
    stickerUrl.searchParams.set("creative_id", this.props.creative.id);
    if (isRaw) {
      stickerUrl.searchParams.set("raw", "1");
    }

    if (clientStorage.getProWatermarkShouldBeRemoved()) {
      stickerUrl.searchParams.set("wwm", "1");
    }

    webviewUtils.webviewShare({
      providers: "[11]",
      caption_text: "",
      attachment_url: encodeURIComponent(shareUrl.toString()),
      sticker_url: encodeURIComponent(stickerUrl.toString()),
      sc_sticker_posx: 0.5,
      sc_sticker_posy: 0.5,
      sc_sticker_rotation: 0.3,
      sc_sticker_width: stickerSize,
      sc_sticker_height: stickerSize,
    });
  };

  renderModal() {
    switch (this.state.view) {
      case "rate":
        return this.renderRateAppModal();
      case "clones":
        return this.renderClonesModal();
      case "snapchat":
        return this.renderSnapchatModal();
      default:
        return this.renderDownloadModal();
    }
  }

  renderSnapchatModal = () => {
    let creativeRawUrl = this.props.creative.steps[0];

    if (!clientStorage.getProWatermarkShouldBeRemoved()) {
      creativeRawUrl = new URL(this.props.photo.urls.creative_raw);
      creativeRawUrl.searchParams.set("creative_id", this.props.creative.id);
    }

    return <div className="modal-container">
      <div className="modal snapchat">
        <SvgModalBackground />
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("snap_dialog__title")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("snap_dialog__message")}} />

        <div className="check-foto-container">
          <div className="check-foto" onClick={() => this.shareSnapchat(false)}>
            <img src={this.props.creative.file.url} alt="Result" />
          </div>
          <div className="check-foto" onClick={() => this.shareSnapchat(true)}>
            <img src={creativeRawUrl} alt="Raw" />
          </div>
        </div>

        {/*<button*/}
        {/*  className="btn-share-snapchat"*/}
        {/*  children={i18n.t("snap_dialog__share_button")} />*/}
        <button
          className="btn-back"
          children={i18n.t("snap_dialog__back_button")}
          onClick={() => this.setState({view: "download"})}/>
      </div>
      <SvgCloudTop />
      <SvgCloudRight />
      <SvgCloudLeft />
    </div>;
  };

  renderClonesModal = () => {
    return <div className="modal-container">
      <div className="modal clones">
        <SvgModalBackground />
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("clones_dialog__title")}} />
        <p className="text-bold" dangerouslySetInnerHTML={{__html: i18n.t("clones_dialog__message")}} />
        <button
          className="btn-upload-foto"
          children={i18n.t("clones_dialog__accept_button")}
          onClick={this.handleClonesDialogAcceptButtonClick} />
        <button
          className="btn-upload-foto download"
          children={i18n.t("clones_dialog__skip_button")}
          onClick={this.handleClonesDialogSkipButtonClick} />
      </div>
      <SvgCloudTop />
      <SvgCloudRight />
      <SvgCloudLeft />
    </div>;
  };

  renderRateAppModal = () => {
    return <div className="modal-container">
      <div className="modal rate">
        <SvgModalBackground />
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("rateapp_dialog__title")}} />
        <p className="text-bold" dangerouslySetInnerHTML={{__html: i18n.t("rateapp_dialog__message-bold")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("rateapp_dialog__message")}} />
        <button
          className="btn-upload-foto"
          children={i18n.t("rateapp_dialog__rate_button")}
          onClick={this.handleRateAppRateButtonClick} />
        <button
          className="btn-upload-foto download"
          children={i18n.t("rateapp_dialog__skip_button")}
          onClick={this.handleRateAppSkipButtonClick} />
      </div>
      <SvgCloudTop />
      <SvgCloudRight />
      <SvgCloudLeft />
    </div>;
  };

  renderDownloadModal = () => {
    const creativeIsProcessed = this.state.creative && creativeUtils.creativeIsProcessed(this.state.creative);
    const creativeIsProcessing = this.state.creative && creativeUtils.creativeIsProcessing(this.state.creative);

    const loader = <div className="loader-video">
      <span className="item-loader first-item-loader" />
      <span className="item-loader second-item-loader" />
      <span className="item-loader third-item-loader" />
    </div>;

    const buildVersionCode = parseInt(window.clientConfig.webviewParams.version_code);
    const isWebviewIOS = window.clientConfig.isWebviewIOS;
    const isWebviewAndroid = window.clientConfig.isWebviewAndroid;
    const snapchatIsAllow = (isWebviewIOS || (isWebviewAndroid && buildVersionCode >= 103))
      && this.context.installedApps.snapchat
      && this.props.tab !== creativeUtils.creativeGroups.CARTOON_ANIM;

    const buttons = <div className="btns-container">
      <div className="btns-container_share">
        <button
          className="btn-share btn-share-fb"
          hidden={window.clientConfig.isWeb || !this.context.installedApps.facebook || (isWebviewIOS && this.props.tab === creativeUtils.creativeGroups.CARTOON_ANIM)}
          onClick={this.handleShareToFacebook}>
          <SvgFacebookShareIcon />
        </button>
        <button
          className="btn-share btn-share-insta"
          hidden={window.clientConfig.isWeb || !this.context.installedApps.instagram}
          onClick={this.handleShareToInstagram}>
          <SvgInstagramShareIcon />
        </button>
        <button
          className="btn-share btn-share-snap"
          hidden={!snapchatIsAllow}
          onClick={this.handleShareToSnapchatButtonClick}>
          <SvgSnapchatShareIcon />
        </button>
        <button
          className="btn-upload-foto"
          children={i18n.t("download_modal__dismiss_button")}
          onClick={this.handleGotItButtonClick} />
      </div>
      <button
        hidden={!this.state.withHD || window.clientConfig.isWebview}
        className="btn-upload-foto download"
        children={i18n.t("download_modal__download_button")}
        onClick={this.handleDownload} />
    </div>;

    return <div className="modal-container">
      <div className="modal">
        <SvgModalBackground />
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("download_modal__title")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("download_modal__message")}} />
        {creativeIsProcessing && <p dangerouslySetInnerHTML={{__html: i18n.t("download_modal__message_wait")}} />}
        {creativeIsProcessed && this.state.withHD && <p dangerouslySetInnerHTML={{__html: i18n.t("download_modal__message_ready")}} />}
        {creativeIsProcessed ? buttons : loader}
        <button
          hidden={!this.state.withHD || creativeIsProcessed}
          className="btn-cancel btn-upload-foto download"
          dangerouslySetInnerHTML={{__html: i18n.t("btn_cancel")}}
          onClick={this.handleCancelSave} />
      </div>
      <SvgCloudTop />
      <SvgCloudRight />
      <SvgCloudLeft />
    </div>;
  };
}

DownloadModal.propTypes = {
  tab: PropTypes.string.isRequired,
  photo: PropTypes.object.isRequired,
  creative: PropTypes.object.isRequired,
  withHD: PropTypes.bool,
  downloadHandler: PropTypes.func.isRequired,
  shareHandler: PropTypes.func.isRequired,
};

export default DownloadModal;