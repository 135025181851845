import React from "react";

export function SvgSprite({className, icon, viewBox = "0 0 16 16", hidden}) {
  if (hidden) {
    return <React.Fragment />;
  }

  return <svg
    className={className}
    viewBox={viewBox}>
    <use xlinkHref={`/assets/img/sprite.svg#${icon}`} />
  </svg>;
}
