export const apiResponseErrorCodes = {
  INTERNAL: 1,
  INVALID_PARAMS: 2,
  PHOTOLAB: 3,
  NOT_AUTHORIZE: 401,
  NOT_FOUND: 404,
  DELETED: 410,
  FILE_FORMAT_INVALID: 415,
};

export class ApiResponseError extends Error {

  constructor(data) {
    super();

    this.name = "ApiResponseError";
    this.code = data.error_code;
    this.message = `Code: ${data.error_code}, Message: ${data.error_message}`;
    this.response = data;
  }
}

export function checkResponse(res) {
  if (res.data.error_code) {
    throw new ApiResponseError(res.data);
  } else {
    return res.data;
  }
}

function paramsToStr(params = {}) {
  params.r = Math.random();

  return Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join("&");
}

export function createPhoto(image, data, params) {
  params = params || {};

  const formData = new FormData();

  if (image instanceof File) {
    formData.append("file", image);
  } else {
    formData.append("image_url", image);
  }

  if (data) {
    formData.append("image_data", JSON.stringify(data || {}));
  }

  Object.keys(params).forEach((pk) => formData.append(pk, params[pk]));

  return window.axios.post(window.appConfig.paths.apiUpload + "/photos/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }).then(checkResponse);
}

export function forkPhoto(photoId, params = {}) {
  params.id = photoId;

  return window.axios.post(window.appConfig.paths.api + "/photos/fork", params).then(checkResponse);
}

export function fetchPhotoById(id, params = {}) {
  return window.axios.get(window.appConfig.paths.api + "/photos/id/" + id + "?" + paramsToStr(params))
    .then(checkResponse);
}

export function fetchPhotoByHash(hash, params = {}) {
  return window.axios.get(window.appConfig.paths.api + "/photos/hash/" + hash + "?" + paramsToStr(params))
    .then(checkResponse);
}

export function fetchPhotoVectorPreviewTask(id) {
  return window.axios.post(window.appConfig.paths.api + "/photos/vector-preview-task", {id})
    .then(checkResponse);
}

export function enqueuePhotoGroup(id, group) {
  return window.axios.post(window.appConfig.paths.api + "/photos/enqueue-group", {id, group})
    .then(checkResponse);
}

export function refreshPhotoGroup(id, group, params = {}) {
  return window.axios.post(window.appConfig.paths.api + "/photos/refresh-group?" + paramsToStr(params), {id, group})
    .then(checkResponse);
}

export function refreshPhotoCollage(id, group) {
  return window.axios.post(window.appConfig.paths.api + "/photos/refresh-collage", {id, group})
    .then(checkResponse);
}

export function refreshPhotoVector(id, templateId) {
  return window.axios.post(window.appConfig.paths.api + "/photos/refresh-vector", {id, template_id: templateId})
    .then(checkResponse);
}

export function fetchCreative(id, creativeId) {
  return window.axios.post(window.appConfig.paths.api + "/photos/creative", {id, creative_id: creativeId})
    .then(checkResponse);
}

export function fetchHDCreative(id, creativeId) {
  return window.axios.post(window.appConfig.paths.api + "/photos/creative-hd", {id, creative_id: creativeId})
    .then(checkResponse);
}

export function logEvent(eventId, eventParams, userParams, webviewParams) {
  return window.axios.post(window.appConfig.analytics.endpoint, {
    id: eventId,
    params: eventParams,
    user_params: userParams,
    webview_params: webviewParams,
  }).then(checkResponse);
}

export function contactUs(name, background, email) {
  return window.axios.post(window.appConfig.paths.api + "/contact/save", {name, background, email})
      .then(checkResponse);
}
