import {getLocationQueryObject} from "./utils/text";
import clientStorage from "./utils/client-storage";
import {detect} from "detect-browser";
import md5 from "md5";

const query = getLocationQueryObject();
const platformInfo = detect();
const platformIsMobile = ["android", "android os", "ios"].indexOf(platformInfo.os.toLowerCase()) > -1;
const isWebview = typeof query["aid"] !== "undefined" || typeof query["vicman_unified_id"] !== "undefined";

window.clientConfig = {
  platform: platformInfo,
  isWeb: !isWebview,
  isWebMobile: !isWebview && platformIsMobile,
  isWebDesktop: !isWebview && !platformIsMobile,
  isWebview: isWebview,
  isWebviewAndroid: typeof query["aid"] !== "undefined",
  isWebviewIOS: typeof query["vicman_unified_id"] !== "undefined",
  isAnyMobile: isWebview || platformIsMobile,
  webviewParams: isWebview ? query : {},
  isPro: query["is_pro"] === "1",
  locale: query["locale"] || query["lang"] || window.navigator.language || undefined,
  splits: {
    groupId: undefined,
  },
};

window.clientConfig.lang = window.clientConfig.locale
  ? window.clientConfig.locale.substring(0, 2).toLowerCase()
  : undefined;

// ---

if (window.clientConfig.isWebview) {
  window.clientConfig.token = window.clientConfig.isWebviewAndroid
    ? `w:a:${query["aid"]}`
    : `w:i:${query["vicman_unified_id"]}`;
} else {
  const clientToken = clientStorage.getClientToken();
  if (clientToken) {
    window.clientConfig.token = clientToken;
  } else {
    const type = platformIsMobile ? "m" : "d";
    const hash = md5([
      Math.random(),
      Date.now(),
      window.navigator.platform,
      window.navigator.userAgent,
      window.navigator.languages.join("/"),
      window.screen.width,
      window.screen.height,
    ].join("+"));

    window.clientConfig.token = `b:${type}:${hash}`;
    clientStorage.setClientToken(window.clientConfig.token);
  }
}

// ---

if (!window.clientConfig.splits.groupId || window.clientConfig.splits.groupId < 0 || window.clientConfig.splits.groupId > 10) {
  if (window.clientConfig.isWebview) {
    const aid = query["aid"] || query["vicman_unified_id"];
    const num = parseInt(aid.substring(aid.length - 2), 16);
    const group = Math.floor(num / (255 / 10)) + 1;

    window.clientConfig.splits.groupId = Math.min(group, 10); // fix for ff => 11 group
  } else {
    const groupId = query["split_group"] || clientStorage.getSplitGroupId();
    if (groupId) {
      window.clientConfig.splits.groupId = parseInt(groupId);
    } else {
      window.clientConfig.splits.groupId = Math.floor(Math.random() * 10) + 1;
    }

    clientStorage.setSplitGroupId(window.clientConfig.splits.groupId);
  }
}

// ---

window.appConfig = {
  designVersion: "v2",

  isDebug: process.env.NODE_ENV.startsWith("prod") === false
    || process.env.REACT_APP_DEBUG === "true"
    || query["vicman_debug"]
    || false,

  paths: {
    app: process.env.REACT_APP_APP_PATH,
    assets: process.env.REACT_APP_ASSETS_PATH,
    api: process.env.REACT_APP_API_PATH,
    apiUpload: process.env.REACT_APP_API_FILES_PATH,
  },

  amplitude: {
    isEnabled: process.env.REACT_APP_AMPLITUDE_ENABLED === "true",
    key: process.env.REACT_APP_AMPLITUDE_KEY,
  },

  analytics: {
    isEnabled: process.env.REACT_APP_ANALYTICS_ENABLED === "true",
    endpoint: process.env.REACT_APP_ANALYTICS_ENDPOINT,
  },

  hits: {
    isEnabled: process.env.REACT_APP_HITS_ENABLED === "true",
    endpoint: process.env.REACT_APP_HITS_ENDPOINT,
  },

  facebook: {
    appId: 202248336556242,
  },

  sentry: {
    isEnabled: process.env.REACT_APP_SENTRY_ENABLED === "true",
    dsn: process.env.REACT_APP_SENTRY_DSN || "",
  },

  googleAnalytics: {
    isEnabled: (process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLED === "true") && window.clientConfig.isWeb,
    trackerId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKER_ID,
  },

  nativeAds: {
    isEnabled: process.env.REACT_APP_NATIVE_ADS_IS_ENABLED === "true",
  },

  webview: {
    uploadEnabledPercent: parseFloat(process.env.REACT_APP_WEBVIEW_UPLOAD_ENABLED),
    uploadDisabledCountries: (process.env.REACT_APP_WEBVIEW_UPLOAD_DISABLED_COUNTRIES || "")
      .split(",")
      .map(c => c.toLowerCase()),
    rateAppDialogPercent: parseFloat(process.env.REACT_APP_WEBVIEW_RATEAPP_DIALOG_PERCENT),
    clonesDialogPercent: parseFloat(process.env.REACT_APP_WEBVIEW_CLONES_DIALOG_PERCENT),
  },
};
