module.exports = {
  "strings": {
    "try_another_photo": "Essayer une autre photo",

    "landing__title_part_1": "Youhou !",
    "landing__title_part_2": "Il est temps de vivre votre rêve de dessin animé, autant de fois que vous le souhaitez.",
    "landing__subtitle": "Il suffit de fournir votre photo à l'IA. 😜",
    "landing__select_photo_button": "Choisissez une photo",
    "landing__get_the_app_button": "Obtenez l'application",
    "loading__uploading": "Téléchargement...",

    "landing__title_labs": "ToonMe.com LABS",
    "landing__subtitle_labs": "Vous êtes à présent dans un espace expérimental spécial. Pour retrouver les styles originaux, vous pouvez revenir à la version classique ci-dessous.",
    "landing__labs_back_button": "Retourner sur la page ToonMe Classic",
    "landing__labs_choice": "Ou essayez nos styles expérimentaux au <span>ToonMe&nbsp;LABS</span>",

    "result__labs_tab_title": "Welcome to ToonMe LABS",
    "result__labs_tab_message": "Nous expérimentons de nouveaux styles et vous êtes invité(e) à en avoir un aperçu! Vous pouvez revenir à la version originale à tout moment.",
    "result__labs_tab_button": "Essayer",
    "result__tab_classic_title": "Vous aimez les effets que vous avez essayé ?",
    "result__tab_classic_info": "Restez ici ou bien revenez à la version classique de ToonMe",
    "result__tab_classic_btn": "Retourner sur la page ToonMe Classic",

    "processing_text_1": "Jaunir votre peau...🖌️",
    "processing_text_2": "Transformer les traits de votre visage en personnage de dessin animé...",
    "processing_text_3": "Vous ressemblez à une princesse de Disney. Je plaisante. Ou pas...🙈",
    "processing_text_4": "Vos rêves deviennent réalité 🦄",
    "processing_text_5": "Transformez votre photo en dessin animé !",
    "processing_text_6": "Transformation en cours !",
    "processing_text_7": "Walt Disney aurait aimé ça ! ✨",

    "collage__select_photo": "Essayez avec votre photo.",
    "collage__download": "Télécharger en HD.",
    "collage__refresh": "Rafraîchir",
    "collage__more_styles": "Plus de styles",

    "download_modal__title": "Ne vous arrêtez pas là !",
    "download_modal__message": "Qu'est-ce que cela fait d'être une star de dessin animé ? Partagez votre moment de gloire sur Instagram en publiant le résultat qui vous plaît le plus !",
    "download_modal__message_wait": "Création du résultat HD…",
    "download_modal__message_ready": "La version HD est prête, le téléchargement a commencé…",
    "download_modal__dismiss_button": "J'ai compris",
    "download_modal__download_button": "Télécharger à nouveau",

    "error__error": "Erreur",

    "internal_error": "Une erreur s'est produite...",
    "error_overload_webview": "Nous sommes terriblement désolés, mais la charge de nos serveurs est élevée en raison du nombre élevé de téléchargements de l'application. Veuillez essayer d'utiliser l'onglet Flux à la place ou revenez nous voir un peu plus tard. Nous travaillons dur pour résoudre tous les problèmes rapidement.",
    "error_overload_web": "Nous sommes terriblement désolés, mais la charge de nos serveurs est élevée en raison du nombre élevé de téléchargements de l'application. Revenez nous voir un peu plus tard. Nous travaillons dur pour résoudre tous les problèmes rapidement.",

    "rateapp_dialog__title": "Vous aimez ToonMe ?",
    "rateapp_dialog__message-bold": "Donnez cinq étoiles à l'application et aidez les autres utilisateurs à la découvrir et à l'aimer !",
    "rateapp_dialog__message": "PS. Cela nous aidera également à rétablir les évaluations, affectées par des problèmes de serveur après que ToonMe soit devenu viral. 🙏",
    "rateapp_dialog__rate_button": "Donner une note !",
    "rateapp_dialog__skip_button": "Passer",

    "btn_store__title":"<span class=\"btn-store-title\">Télécharger sur</span><span class=\"btn-store-name\">{{market}}</span>",
    "btn_store__ios":"App Store",
    "btn_store__android":"Google Play",
    "btn_cancel": "Supprimer et sauvegarder en qualité standard",
    "btn_clone": "Beware of cheap clone!",
    "btn_contacts_form": "Contactez-nous",
    "btn_back": "back",

    "vector_tab_refresh__title": "On y est presque !",
    "vector_tab_refresh__subtitle": "Choisir un portrait",

    "updated_at": "Une mise à jour qui a apporté de nouveaux effets a été effectuée le <span class=\"updated-at-date\">{{date}}</span>",

    "warning_clones": "ATTENTION !",
    "warning_clones_link": "MÉFIEZ-VOUS DES CLONES BAS DE GAMME",

    "snap_dialog__title": "Avant-après ou juste TOON ?",
    "snap_dialog__message": "Choisir comment le snaper !",
    "snap_dialog__share_button": "Partager",
    "snap_dialog__back_button": "Précédent",

    "title_contacts_form": "Créée par les développeurs de Photo Lab, la meilleure application de photographie au monde sur iOS et Android, avec 260 millions de téléchargements – un nombre qui augmente constamment…",

    "toggle_watermark_tooltip__text": "Appuyez sur le logo pour le supprimer",
    "toggle_watermark_on": "Avec logo",
    "toggle_watermark_off": "Sans logo",

    "remove_logo_modal__title": "À garder ou à ne pas garder...",
    "remove_logo_modal__message": "Hé, saviez-vous que ce logo fait partie du design de cet effet ? Ça nous fera plaisir si vous choisissez de le garder.",
    "remove_logo_modal__btn_cancel": "Garder le logo",
    "remove_logo_modal__btn_remove": "Supprimer quand même",

    // v2
    "landing__toonme_on_instagram": "#ToonMe sur Instagram",
  },
};
