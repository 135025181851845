import React from 'react';
import i18n from "../../i18n";
import {generatePath} from "react-router";
import routes from "../../routes";
import * as api from "../../utils/api";
import Loading from "../../components/Loading";
import FileChooseButton from "../../components/FileChooseButton";
import HomeButton from "../../components/HomeButton";
import AppContext from "../../contexts/AppContext";
import * as creativeUtils from "../../utils/creative";
import {getLocationQueryObject} from "../../utils/text";
import {hitEvent, hits, logEvent, userEvents} from "../../utils/log";
import * as webviewUtils from "../../utils/webview";
import {ApiResponseError} from "../../utils/api";
import {debounce, prefixRouteForSite, when} from "../../utils/etc";
import clientStorage from "../../utils/client-storage";
import SimpleTab from "./SimpleTab";
import LabsTab from "./LabsTab";
import ClassicTab from "./ClassicTab";
import VectorTab from "./VectorTab";
import RemoveLogoModal from "./RemoveLogoModal";
import DownloadModal from "./DownloadModal";
import LoveTab from "./LoveTab";
import LoadingV2 from "../../components/LoadingV2";

const FETCH_INTERVAL = 2000;

const SHARE_PROVIDER_SNAPCHAT = "snapchat";
const SHARE_PROVIDER_FACEBOOK = "facebook";
const SHARE_PROVIDER_INSTAGRAM = "instagram";

export default class ResultPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      tabs: [],
      tab: creativeUtils.creativeGroups.DISNEY,
      defaultTab: creativeUtils.creativeGroups.DISNEY,
      photo: null,
      creatives: null,
    };

    this.fecthTimer = null;
    this.hitProcessingFailedOnResultPageIsTriggered = false;

    if (clientStorage.getProWatermarkShouldBeRemoved()) {
      const choiceAt = clientStorage.getProWatermarkRemoveChoiceAt();
      if (Date.now() - choiceAt >= 86400000) {
        clientStorage.setProWatermarkShouldBeRemoved(false);
        clientStorage.setProWatermarkTooltipIsHidden(false);
      }
    }
  }

  componentDidMount() {
    this.fetchResult();
  }

  componentWillUnmount() {
    clearTimeout(this.fecthTimer)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.hash !== prevProps.match.params.hash) {
      this.setState({
        isLoading: true
      }, () => {
        this.fetchResult();
      });
    }
  }

  fetchResultTimeout = () => {
    this.fecthTimer = debounce("ResultPage_fetchResult", FETCH_INTERVAL, this.fetchResult);
  };

  fetchResult = () => {
    const params = {
      site: this.props.site,
    };

    api.fetchPhotoByHash(this.props.match.params.hash, params)
      .then(this.handleResultFetched)
      .catch((err) => {
        console.error(err);

        if (err instanceof ApiResponseError) {
          this.props.history.replace(routes.ERROR);
        } else {
          this.fetchResultTimeout();
        }
      });
  };

  toggleCreativesFiles = (creatives) => {
    if (window.clientConfig.isPro && clientStorage.getProWatermarkShouldBeRemoved()) {
      creatives.forEach((creative) => {
        if (creative.file && creative.steps.length) {
          creative.file.url = creative.steps[creative.steps.length - 1];
        }
      });
    }

    if (clientStorage.getBeforePhotoShouldBeRemoved()) {
      creatives.forEach((creative) => {
        const noBeforeFile = creative.files.find((creative) => creative["alias"] === "no_before");
        creative.file = noBeforeFile || creative.file;
      });
    }

    return creatives;
  };

  handleResultFetched = (res) => {
    const query = getLocationQueryObject();
    const isNewResult = this.state.photo === null || this.state.photo.id !== res.photo.id;
    const nextState = {
      isLoading: false,
      photo: res.photo,
      creatives: this.toggleCreativesFiles(res.creatives),
      tabs: res.tabs,
    };

    if (isNewResult) {
      let tab;

      if (nextState.tabs.indexOf(query.t) > -1) {
        tab = query.t;
      } else {
        // const availableGroups = nextState.creatives.map((creative) => creative.group);
        // const tabsWithChances = Object.keys(TABS_CHANCES);
        // const dice = Math.random() * 100;
        //
        // tab = tabsWithChances.find((key) => {
        //   const range = TABS_CHANCES[key];
        //   return dice >= range[0] && dice < range[1]
        // });
        //
        // tab = (tab && availableGroups.indexOf(tab) > -1) ? tab : creativeUtils.creativeGroups.DISNEY;
        tab = when(this.props.site, {
          "classic": creativeUtils.creativeGroups.DISNEY,
          "love": creativeUtils.creativeGroups.CUPID21_2,
          "labs": creativeUtils.creativeGroups.CARTOON_ANIM,
        });
      }

      nextState.defaultTab = tab;
      nextState.tab = tab;

      if (window.clientConfig.isWebview) {
        this.props.history.replace({
          pathname: generatePath(prefixRouteForSite(routes.RESULT, this.props.site), {hash: nextState.photo.hash}),
          search: `?t=${nextState.tab}`,
        });
      }

      logEvent(userEvents.PAGE_RESULT, {
        photo_id: nextState.photo.id,
        is_owner: nextState.photo.is_owner,
        group: tab,
        ref: nextState.photo.is_owner ? undefined : (query.ref || "unspecified"),
      });

      this.hitProcessingFailedOnResultPageIsTriggered = false;
    }
    else {
      const groupCreatives = nextState.creatives.filter((c) => c.group === this.state.tab);
      const failedGroupCreative = groupCreatives.find(creativeUtils.creativeIsFailed);

      if (groupCreatives.length === 1 && failedGroupCreative) {
        const processedCreative = nextState.creatives.find(creativeUtils.creativeIsProcessed);
        const processingCreative = nextState.creatives.find(creativeUtils.creativeIsProcessing);

        logEvent(userEvents.TAB_FAILED, {
          group: this.state.tab,
          photo_id: nextState.photo.id,
          creative_id: failedGroupCreative.id,
          template_id: failedGroupCreative.template.id,
        });

        if (processedCreative) {
          nextState.tab = processedCreative.group;
        } else if (processingCreative) {
          nextState.tab = processingCreative.group;
        } else {
          nextState.tab = creativeUtils.creativeGroups.DISNEY;
        }
      }
    }

    let cb = undefined;

    if (nextState.creatives.filter(creativeUtils.creativeIsProcessing).length > 0) {
      cb = () => {
        this.fetchResultTimeout();
      };
    }

    const failedCreatives = nextState.creatives.filter(creativeUtils.creativeIsFailed);
    if (failedCreatives.length > 0) {
      if (!this.hitProcessingFailedOnResultPageIsTriggered) {
        this.hitProcessingFailedOnResultPageIsTriggered = true;
        hitEvent(hits.PROCESSING_FAILED_ON_RESULT_PAGE);
      }
    }

    this.setState(nextState, cb);
  };

  handleFileSelected = (file) => {
    hitEvent(hits.PHOTO_SELECT);
    logEvent(userEvents.PHOTO_SELECT, {page: "result"});

    this.props.history.push(prefixRouteForSite(routes.CREATE, this.props.site), {file});
  };

  handleImageLoaded = (creative) => {
    logEvent(userEvents.CREATIVE_VIEW, {
      ...getShareEventProps(this.state, creative),
      original_is_hide: clientStorage.getBeforePhotoShouldBeRemoved(),
    });
  };

  handleShare = (provider, sdCreative, hdCreative, props) => {
    props = props || {};

    switch (provider) {
      case SHARE_PROVIDER_SNAPCHAT: {
        hitEvent(hits.SHARE_CLICK_SNAPCHAT);
        break;
      }
      case SHARE_PROVIDER_FACEBOOK: {
        hitEvent(hits.SHARE_CLICK_FACEBOOK);
        break;
      }
      case SHARE_PROVIDER_INSTAGRAM: {
        hitEvent(hits.SHARE_CLICK_INSTAGRAM);
        break;
      }
      default:
        break;
    }

    logEvent(userEvents.SHARE, {
      provider,
      ...props,
      ...getShareEventProps(this.state, sdCreative, hdCreative),
      original_is_hide: clientStorage.getBeforePhotoShouldBeRemoved(),
    });
  };

  handleDownload = (creative, hdCreative) => {
    logEvent(userEvents.DOWNLOAD_START, {
      ...getShareEventProps(this.state, creative, hdCreative),
      original_is_hide: clientStorage.getBeforePhotoShouldBeRemoved(),
    });

    setTimeout(() => {
      if (window.clientConfig.isWebview) {
        webviewUtils.webviewShareDownload(creative.file.url);
      } else {
        const url = new URL(this.state.photo.urls.download);
        url.searchParams.append("creative_id", hdCreative.id);
        if (clientStorage.getBeforePhotoShouldBeRemoved()) {
          url.searchParams.append("no_before", "1");
        }

        window.location.href = url.toString();
      }
    }, 100);
  };

  showDownloadModal = (creative, withHD) => {
    this.context.pushModal(<DownloadModal
      key="ResultPage-DownloadModal"
      site={this.props.site}
      history={this.props.history}
      tab={this.state.tab}
      photo={this.state.photo}
      creative={creative}
      withHD={withHD}
      downloadHandler={this.handleDownload}
      shareHandler={this.handleShare}
    />);
  };

  handleDownloadButtonClick = (e, creative, withHD) => {
    logEvent(userEvents.DOWNLOAD, {
      ...getShareEventProps(this.state, creative),
      original_is_hide: clientStorage.getBeforePhotoShouldBeRemoved(),
    });

    this.showDownloadModal(creative, withHD);
  };

  handleRefreshButtonClick = (group) => debounce("ResultPage_handleRefreshButtonClick", 100, () => {
    logEvent(userEvents.TAB_REFRESH, {group});

    const params = {
      site: this.props.site,
    };

    api.refreshPhotoGroup(this.state.photo.id, group, params)
      .then(this.handleResultFetched)
      .catch(() => {/* temp ignore */});
  });

  handleRefreshCollageButtonClick = (group) => debounce("ResultPage_handleRefreshCollageButtonClick", 100, () => {
    logEvent(userEvents.TAB_REFRESH, {group});

    api.refreshPhotoCollage(this.state.photo.id, group)
      .then(this.handleResultFetched)
      .catch(() => {/* temp ignore */});
  });

  handleVectorRefreshButtonClick = (templateId) => debounce("ResultPage_handleVectorRefreshButtonClick", 100, () => {
    logEvent(userEvents.TAB_REFRESH, {group: "vector", template_id: templateId});

    api.refreshPhotoVector(this.state.photo.id, templateId)
      .then(this.handleResultFetched)
      .catch(() => {/* temp ignore */});
  });

  showRemoveLogoModal = () => {
    this.context.pushModal(<RemoveLogoModal
      key="ResultPage-RemoveLogoModal"
      onShowButtonClick={this.handleShowWatermak}
      onHideButtonClick={this.handleHideWatermak}
    />);
  };

  handleToggleWatermak = () => debounce("ResultPage_handleToggleWatermak", 100, () => {
    clientStorage.setProWatermarkTooltipIsHidden(true);

    this.showRemoveLogoModal();
  });

  handleHideBeforePhotoButtonClick = () => {
    clientStorage.setBeforePhotoShouldBeRemoved(true);
    this.setState({isLoading: true}, this.fetchResultTimeout);
  };

  handleShowBeforePhotoButtonClick = () => {
    clientStorage.setBeforePhotoShouldBeRemoved(false);
    this.setState({isLoading: true}, this.fetchResultTimeout);
  };

  handleLabsButtonClick = () => debounce("ResultPage_handleLabsButtonClick", 100, () => {
    api.forkPhoto(this.state.photo.id, {site: "labs"}).then((res) => {
      if (res.photo.hash) {
        this.props.history.push(generatePath(prefixRouteForSite(routes.RESULT, "labs"), {hash: res.photo.hash}));
      } else {
        this.props.history.push(generatePath(prefixRouteForSite(routes.PROCESSING, "labs"), {id: res.photo.id}));
      }
    }).catch((err) => {
      console.error(err);
    });
  });

  handleLoveButtonClick = () => debounce("ResultPage_handleLoveButtonClick", 100, () => {
    if (window.clientConfig.isWeb) {
      window.location.href = window.appConfig.paths.app.replace("://", "://cupid.");
    } else {
      api.forkPhoto(this.state.photo.id, {site: "love"}).then((res) => {
        if (res.photo.hash) {
          this.props.history.push(generatePath(prefixRouteForSite(routes.RESULT, "love"), {hash: res.photo.hash}));
        } else {
          this.props.history.push(generatePath(prefixRouteForSite(routes.PROCESSING, "love"), {id: res.photo.id}));
        }
      }).catch((err) => {
        console.error(err);
      });
    }
  });

  handleClassicButtonClick = () => debounce("ResultPage_handleClassicButtonClick", 100, () => {
    if (this.props.site === "love" && window.clientConfig.isWeb) {
      window.location.href = window.appConfig.paths.app;
      return;
    }

    api.forkPhoto(this.state.photo.id, {site: "classic"}).then((res) => {
      if (res.photo.hash) {
        this.props.history.push(generatePath(prefixRouteForSite(routes.RESULT, "classic"), {hash: res.photo.hash}));
      } else {
        this.props.history.push(generatePath(prefixRouteForSite(routes.PROCESSING, "classic"), {id: res.photo.id}));
      }
    }).catch((err) => {
      console.error(err);
    });
  });

  handleShowWatermak = () => {
    clientStorage.setProWatermarkShouldBeRemoved(false);

    this.fetchResult();
  };

  handleHideWatermak = () => {
    clientStorage.setProWatermarkRemoveChoiceAt(Date.now());
    clientStorage.setProWatermarkShouldBeRemoved(true);

    this.fetchResult();
  };

  handleTabClick = (group) => {
    if (this.state.tab === group) {
      return;
    }

    if (window.clientConfig.isWebview) {
      this.props.history.replace({
        pathname: generatePath(prefixRouteForSite(routes.RESULT, this.props.site), {hash: this.state.photo.hash}),
        search: `?t=${group}`,
      });
    }

    const creativesInGroup = this.state.creatives.filter((creative) => creative.group === group);

    logEvent(userEvents.TAB_SELECT, {
      group,
      group_is_empty: creativesInGroup.length === 0,
      prev_group: this.state.tab,
    });

    if (creativesInGroup.length === 0 && group !== "labs" && group !== "classic") {
      api.enqueuePhotoGroup(this.state.photo.id, group)
        .then(this.handleResultFetched)
        .catch(() => {/* temp ignore */});
    }

    this.setState({tab: group});
  };

  renderTab = (group) => {
    const tabClassNames = ["collage__tab_" + group];
    if (this.state.tab === group) {
      tabClassNames.push("active-tab");
    }

    const creative = this.state.creatives.find((creative) => creative.group === group && creative.is_selected);
    let preview = undefined;
    if (creative && creativeUtils.creativeIsProcessed(creative)) {
      preview = <img src={creative.file.url} alt="Preview" />;
    }

    if (group === creativeUtils.creativeGroups.CARTOON_ANIM) {
      preview = undefined;
    }
    
    const newLabelUpd = (group === creativeUtils.creativeGroups.DISNEY_2D)
      ? <span className="new-label-upd">UPDATED</span>
      : undefined;

    const newLabel = false
      ? <span className="new-label">new</span>
      : undefined;

    return <button
      key={group}
      className={tabClassNames.join(" ")}
      onClick={() => this.handleTabClick(group)}>
      {newLabel}
      {newLabelUpd}
      {preview}
    </button>;
  };

  renderTabContainer = (commonTabSettings) => {
    commonTabSettings.group = this.state.tab;

    switch (this.state.tab) {
      case creativeUtils.creativeGroups.BARBIE:
        return <SimpleTab {...commonTabSettings} canBeRefreshed={false} />;
      case creativeUtils.creativeGroups.CARTOON:
        return <SimpleTab {...commonTabSettings} />;
      case creativeUtils.creativeGroups.CARTOON_ANIM:
        return <SimpleTab {...commonTabSettings} canBeRefreshed={false} />;
      case creativeUtils.creativeGroups.T4532:
        return <SimpleTab {...commonTabSettings} />;
      case creativeUtils.creativeGroups.CARICATURE:
      case creativeUtils.creativeGroups.CARICATURE_2:
      case creativeUtils.creativeGroups.CARICATURE_3:
        return <SimpleTab {...commonTabSettings} withHD={this.state.tab === creativeUtils.creativeGroups.CARICATURE_3} />;
      case creativeUtils.creativeGroups.SIMPSONS:
        return <SimpleTab {...commonTabSettings} />;
      case creativeUtils.creativeGroups.CUPID21_1:
        return <SimpleTab {...commonTabSettings} canBeRefreshed={false} />;
      case creativeUtils.creativeGroups.CUPID21_2:
        return <SimpleTab {...commonTabSettings} />;
      case creativeUtils.creativeGroups.DISNEY:
        return <SimpleTab {...commonTabSettings} withHD={true} />;
      case creativeUtils.creativeGroups.DISNEY_2D:
        return <SimpleTab {...commonTabSettings} withHD={true} />;
      case creativeUtils.creativeGroups.ARCHER:
        return <SimpleTab {...commonTabSettings} withHD={true} />;
      case creativeUtils.creativeGroups.VECTOR:
        return <VectorTab {...commonTabSettings} withHD={true} onTemplateSelect={this.handleVectorRefreshButtonClick} />;
      case creativeUtils.creativeGroups.COLLAGE:
        return <SimpleTab {...commonTabSettings} canBeRefreshed={false} onRefreshButtonClick={this.handleRefreshCollageButtonClick} />;
      case "labs":
        return <LabsTab onButtonClick={this.handleLabsButtonClick} />;
      case "classic":
        return <ClassicTab onButtonClick={this.handleClassicButtonClick} />;
      case "love":
        return <LoveTab onButtonClick={this.handleLoveButtonClick} />;
      default: {
        return <div />;
      }
    }
  };

  getViewParams = () => {
    const isOwner = this.state.photo.is_owner;
    const isGuest = !isOwner;

    const commonTabSettings = {
      photo: this.state.photo,
      creatives: this.state.creatives,
      isOwner,
      isGuest,
      onImageLoaded: this.handleImageLoaded,
      onDownloadButtonClick: (e, creative, withHD) => this.handleDownloadButtonClick(e, creative, withHD),
      onRefreshButtonClick: this.handleRefreshButtonClick,
      onShowWatermarkButtonClick: this.handleShowWatermak,
      onHideWatermarkButtonClick: this.handleToggleWatermak,
      onHideBeforePhotoButtonClick: this.handleHideBeforePhotoButtonClick,
      onShowBeforePhotoButtonClick: this.handleShowBeforePhotoButtonClick,
      canBeRefreshed: this.state.photo.can_refresh,
      onFileSelected: this.handleFileSelected,
      site: this.props.site,
    };

    const showTabs = this.state.tabs.filter((group) => {
      const groupCreatives = this.state.creatives.filter((c) => c.group === group);
      const failedCreative = groupCreatives.find(creativeUtils.creativeIsFailed);

      // коллаж покажем только когда появится его обработка
      if (group === creativeUtils.creativeGroups.COLLAGE) {
        return groupCreatives.length > 0;
      }

      return !(groupCreatives.length === 1 && failedCreative);
    });

    if (this.props.site === "labs") {
      showTabs.splice(0, 0, "classic");
    } else if (this.props.site === "classic") {
      showTabs.push("labs");
    }

    return {isOwner, isGuest, commonTabSettings, showTabs};
  };

  renderView = ({isOwner, isGuest, commonTabSettings, showTabs}) => {
    return <main className={"collage-page" + (this.props.site === "labs" ? " labs-container" : "")}>
      <div className="container">
        <HomeButton hidden={isGuest} page={"result"} site={this.props.site} />
        <h1 hidden={isOwner} className="main-title">{i18n.t("landing__title_part_1")} {i18n.t("landing__title_part_2")}</h1>
      </div>
      <div className="collage-page-content container">
        <div className="tabs-container">
          {showTabs.map((group) => this.renderTab(group))}
        </div>
        {this.renderTabContainer(commonTabSettings)}
      </div>

      <div className="container" hidden={isOwner}>
        <FileChooseButton
          onFileSelected={this.handleFileSelected}
          className="btn-upload-foto"
          children={i18n.t("collage__select_photo")} />
      </div>
    </main>;
  };

  render() {
    if (this.state.isLoading) {
      const photo = this.props.location.state
        && this.props.location.state.photo
        && this.props.location.state.photo.file
        && this.props.location.state.photo.file.url;

      return (window.appConfig.designVersion === "v2" || this.props.site === "love")
        ? <LoadingV2 site={this.props.site} />
        : <Loading image={photo} site={this.props.site} />;
    }

    const params = this.getViewParams();

    return this.renderView(params);
  }
}

ResultPage.contextType = AppContext;

export function getShareEventProps(state, creative, hdCreative) {
  const props = {
    photo_id: state.photo.id,
    creative_id: creative.id,
    template_id: creative.template.id,
    group: state.tab,
    default_tab_group: state.defaultTab,
  };

  if (hdCreative) {
    props.sr_creative_id = hdCreative.id;
    props.sr_template_id = hdCreative.template.id;
  }

  return props;
}