import React from 'react';
import routes from "../routes";
import * as api from "../utils/api";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import Loading from "../components/Loading";
import LoadingV2 from "../components/LoadingV2";
import AppContext from "../contexts/AppContext";
import {generatePath} from "react-router";
import {creativeIsFailed} from "../utils/creative";
import {ApiResponseError} from "../utils/api";
import {webviewPreloadAd, webviewShowAd} from "../utils/webview";
import clientStorage from "../utils/client-storage";
import {prefixRouteForSite} from "../utils/etc";

const FETCH_INTERVAL = 2000;

const PHOTO_STATUS_FAILED = -1;
const PHOTO_STATUS_PROCESSED = 1;

export default class ProcessingPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      photo: null,
    };
  }

  componentDidMount() {
    this.photoCreatedAt = Date.now();
    this.fetchPhoto(this.props.match.params.id);

    if (window.clientConfig.isWebview && window.appConfig.nativeAds.isEnabled) {
      const isSkipAd = this.props.location.state && this.props.location.state.skipAd === true;

      const nativeAdsIsSupport = window.clientConfig.isWebviewAndroid
        ? (window.clientConfig.webviewParams.version_code >= 153)
        : (window.clientConfig.webviewParams.version_code >= 703);

      if (nativeAdsIsSupport && window.clientConfig.isPro === false && !isSkipAd) {
        hitEvent(hits.NATIVE_ADS_SHOW_REQUEST);
        webviewShowAd(this.handleWebviewAdShown, this.handleWebviewAdClosed);
      }
    }

    clientStorage.incrementProcessingsAmount();
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
  }

  handleWebviewAdShown = (params) => {
    console.log("handleWebviewAdShown", params);
    hitEvent(hits.NATIVE_ADS_SHOWN);
  };

  handleWebviewAdClosed = (params) => {
    console.log("handleWebviewAdClosed", params);
    webviewPreloadAd();
  };

  fetchPhoto = (photoId) => {
    const params = {
      site: this.props.site
    };

    api.fetchPhotoById(photoId, params)
      .then(this.handlePhotoStatus)
      .catch((err) => {
        if (err instanceof ApiResponseError) {
          console.error(err);
          this.props.history.replace(prefixRouteForSite(routes.ERROR, this.props.site));
        } else {
          this.fetchTimer = setTimeout(() => this.fetchPhoto(this.state.photo.id), FETCH_INTERVAL);
        }
      });
  };

  handlePhotoStatus = (res) => {
    if (res.photo.status === PHOTO_STATUS_PROCESSED) {
      hitEvent(hits.PROCESSING_PROCESSED);

      if (!clientStorage.getHasProcessedProcessing()) {
        clientStorage.setHasProcessedProcessing(true);
        hitEvent(hits.PROCESSING_PROCESSED_FIRST);
      }

      logEvent(userEvents.PROCESSING_PROCESSED, {
        elapsed_time_ms: Date.now() - this.photoCreatedAt,
      });

      this.props.history.replace({
        pathname: generatePath(prefixRouteForSite(routes.RESULT, this.props.site), {hash: res.photo.hash}),
        state: {photo: res.photo}
      });
      return;
    }

    if (res.photo.status === PHOTO_STATUS_FAILED) {
      const failedCreativeWithError = res.creatives.find((creative) => {
        return creativeIsFailed(creative) && creative.error;
      });

      hitEvent(failedCreativeWithError
        ? hits.PROCESSING_FAILED_PHOTOLAB
        : hits.PROCESSING_FAILED);

      logEvent(userEvents.PROCESSING_FAILED, {
        elapsed_time_ms: Date.now() - this.photoCreatedAt,
      });

      this.props.history.replace({
        pathname: prefixRouteForSite(routes.ERROR, this.props.site),
        state: {
          message: failedCreativeWithError ? failedCreativeWithError.error : undefined,
          photolabCode: failedCreativeWithError ? failedCreativeWithError.error_code : undefined,
        }
      });
      return;
    }

    this.setState({photo: res.photo});
    this.fetchTimer = setTimeout(() => this.fetchPhoto(res.photo.id), FETCH_INTERVAL);
  };

  render() {
    return (window.appConfig.designVersion === "v2" || this.props.site === "love")
      ? <LoadingV2 withProcessingText site={this.props.site} />
      : <Loading withProcessingText image={this.state.photo && this.state.photo.file.url} site={this.props.site} />;
  }
}

ProcessingPage.contextType = AppContext;