import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/string/starts-with";

require("./config");
require("./utils/ga");

window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["X-Client-Token"] = window.clientConfig.token;

require("./webview");
require("./app");