import React from "react";
import i18n from "../../i18n";
import RemoveLogoModalDefault from "../result/RemoveLogoModal";

export default class RemoveLogoModal extends RemoveLogoModalDefault {
  renderModal() {
    return <div className="modal-container">
      <div className="modal modal-remove-logo">
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("remove_logo_modal__title")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("remove_logo_modal__message")}} />
        <div className="btns-container">
          <button
            className="btn-paint"
            children={i18n.t("remove_logo_modal__btn_cancel")}
            onClick={this.handleCancelButtonClick} />
          <button
          className="btn-empty"
          dangerouslySetInnerHTML={{__html: i18n.t("remove_logo_modal__btn_remove")}}
          onClick={this.handleRemoveButtonClick} />
        </div>
      </div>
    </div>;
  }
}
