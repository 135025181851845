export default {
  INDEX: "/",
  ERROR: "/error",
  CREATE: "/create",
  HIRING_JAPAN: "/hiring-ja",
  PROCESSING: "/processing/:id",
  RESULT: "/r/:hash",

  PRIVACY: "/privacy",
  OFFICIAL: "/official.html",
  CLONEFIGHTERS: "/clonefighters",
  INSTAGRAM: "/instagram",

  LABS_INDEX: "/labs",
  LOVE_INDEX: "/love",
};
