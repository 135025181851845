import React from "react";
import i18n from "../../i18n";
import clientStorage from "../../utils/client-storage";
import * as creativeUtils from "../../utils/creative";
import {SvgSprite} from "../../components/SvgSprite";
import {
  SvgFacebookShareIcon,
  SvgInstagramShareIcon,
  SvgSnapchatShareIcon
} from "../../ui/svg";
import DownloadModalDefault from "../result/DownloadModal";

export default class DownloadModal extends DownloadModalDefault {
  renderModal() {
    switch (this.state.view) {
      case "rate":
        return this.renderRateAppModal();
      case "clones":
        return this.renderClonesModal();
      case "snapchat":
        return this.renderSnapchatModal();
      default:
        return this.renderDownloadModal();
    }
  }

  renderSnapchatModal = () => {
    let creativeRawUrl = this.props.creative.steps[0];

    if (!clientStorage.getProWatermarkShouldBeRemoved()) {
      creativeRawUrl = new URL(this.props.photo.urls.creative_raw);
      creativeRawUrl.searchParams.set("creative_id", this.props.creative.id);
    }

    return <div className="modal-container snapchat">
      <div className="modal">
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("snap_dialog__title")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("snap_dialog__message")}} />

        <div className="check-foto-container">
          <div className="check-foto" onClick={() => this.shareSnapchat(false)}>
            <img src={this.props.creative.file.url} alt="Result" />
          </div>
          <div className="check-foto" onClick={() => this.shareSnapchat(true)}>
            <img src={creativeRawUrl} alt="Raw" />
          </div>
        </div>

        {/*<button*/}
        {/*  className="btn-share-snapchat"*/}
        {/*  children={i18n.t("snap_dialog__share_button")} />*/}
        <button
          className="btn-back"
          onClick={() => this.setState({view: "download"})}>
            <SvgSprite viewBox="0 0 32 54" icon="icon-back" />
            <span children={i18n.t("snap_dialog__back_button")} />
        </button>
      </div>
    </div>;
  };

  renderClonesModal = () => {
    return <div className="modal-container">
      <div className="modal clones">
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("clones_dialog__title")}} />
        <p className="text-bold" dangerouslySetInnerHTML={{__html: i18n.t("clones_dialog__message")}} />
        <button
          className="btn-upload-foto"
          children={i18n.t("clones_dialog__accept_button")}
          onClick={this.handleClonesDialogAcceptButtonClick} />
        <button
          className="btn-upload-foto download"
          children={i18n.t("clones_dialog__skip_button")}
          onClick={this.handleClonesDialogSkipButtonClick} />
      </div>
    </div>;
  };

  renderRateAppModal = () => {
    return <div className="modal-container rate">
      <div className="modal">
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("rateapp_dialog__title")}} />
        <p className="text-bold" dangerouslySetInnerHTML={{__html: i18n.t("rateapp_dialog__message-bold")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("rateapp_dialog__message")}} />
        <button
          className="btn-rate"
          children={i18n.t("rateapp_dialog__rate_button")}
          onClick={this.handleRateAppRateButtonClick} />
        <button
          className="btn-skip"
          children={i18n.t("rateapp_dialog__skip_button")}
          onClick={this.handleRateAppSkipButtonClick} />
      </div>
    </div>;
  };

  renderDownloadModal = () => {
    const creativeIsProcessed = this.state.creative && creativeUtils.creativeIsProcessed(this.state.creative);
    const creativeIsProcessing = this.state.creative && creativeUtils.creativeIsProcessing(this.state.creative);

    const loader = <div className="loader-roller-wrapper">
      <div className="loader-roller loader-roller1">
        <div className="roller" />
        <div className="roller" />
      </div>
      
      <div className="loader-roller loader-roller2">
        <div className="roller" />
        <div className="roller" />
      </div>
      
      <div className="loader-roller loader-roller3">
        <div className="roller" />
        <div className="roller" />
      </div>
    </div>;

    const buildVersionCode = parseInt(window.clientConfig.webviewParams.version_code);
    const isWebviewIOS = window.clientConfig.isWebviewIOS;
    const isWebviewAndroid = window.clientConfig.isWebviewAndroid;
    const snapchatIsAllow = (isWebviewIOS || (isWebviewAndroid && buildVersionCode >= 103))
      && this.context.installedApps.snapchat
      && this.props.tab !== creativeUtils.creativeGroups.CARTOON_ANIM;

    const buttons = <div className="btns-container">
      <div className="btns-container_share">
        <button
          className="btn-share btn-share-fb"
          hidden={window.clientConfig.isWeb || !this.context.installedApps.facebook || (isWebviewIOS && this.props.tab === creativeUtils.creativeGroups.CARTOON_ANIM)}
          onClick={this.handleShareToFacebook}>
          <SvgFacebookShareIcon />
        </button>
        <button
          className="btn-share btn-share-insta"
          hidden={window.clientConfig.isWeb || !this.context.installedApps.instagram}
          onClick={this.handleShareToInstagram}>
          <SvgInstagramShareIcon />
        </button>
        <button
          className="btn-share btn-share-snap"
          hidden={!snapchatIsAllow}
          onClick={this.handleShareToSnapchatButtonClick}>
          <SvgSnapchatShareIcon />
        </button>
        <button
          className="btn-upload-foto"
          children={i18n.t("download_modal__dismiss_button")}
          onClick={this.handleGotItButtonClick} />
      </div>
      <button
        hidden={!this.state.withHD || window.clientConfig.isWebview}
        className="btn-upload-foto download"
        onClick={this.handleDownload}>
          <SvgSprite viewBox="0 0 24 24" icon="icon-download" />
          <span children={i18n.t("download_modal__download_button")} />
        </button>
    </div>;

    return <div className="modal-container">
      <div className="modal">
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("download_modal__title")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("download_modal_love__message")}} />
        {creativeIsProcessing && <p dangerouslySetInnerHTML={{__html: i18n.t("download_modal__message_wait")}} />}
        {creativeIsProcessed && this.state.withHD && <p className="modal-text-processing" dangerouslySetInnerHTML={{__html: i18n.t("download_modal__message_ready")}} />}
        {creativeIsProcessed ? buttons : loader}
        <button
          hidden={!this.state.withHD || creativeIsProcessed}
          className="btn-cancel btn-upload-foto"
          onClick={this.handleCancelSave}>
            <SvgSprite viewBox="0 0 16 16" icon="icon-close" />
            <span dangerouslySetInnerHTML={{__html: i18n.t("btn_cancelV2")}} />
        </button>
      </div>
    </div>;
  };
}
