module.exports = {
  "strings": {
    "try_another_photo": "Ein anderes Foto ausprobieren",

    "landing__title_part_1": "Woo Hoo!",
    "landing__title_part_2": "Es ist Zeit, deinen Cartoon-Traum zu leben, und zwar mehrmals.",
    "landing__subtitle": "Gib dein Foto einfach an AI 😜",
    "landing__select_photo_button": "Wählen Sie ein Foto",
    "landing__get_the_app_button": "Holen Sie sich die App",
    "loading__uploading": "Laden...",

    "landing__title_labs": "ToonMe.com LABS",
    "landing__subtitle_labs": "Sie sind jetzt an einem speziellen Ort zum Experimentieren. Für den ursprünglichen Stil wechseln Sie einfach unten zur Hauptversion zurück.",
    "landing__labs_back_button": "Zurück zu ToonMe Classic",
    "landing__labs_choice": "Oder probieren Sie unsere experimentellen Stile in <span>ToonMe&nbsp;LABS</span> aus.",

    "result__labs_tab_title": "Welcome to ToonMe LABS",
    "result__labs_tab_message": "Wir experimentieren mit neuen Stilrichtungen und Sie können einen ersten Blick darauf erhaschen! Sie können jederzeit zum ursprünglichen Stil zurückwechseln.",
    "result__labs_tab_button": "Ausprobieren",
    "result__tab_classic_title": "Gefallen Ihnen experimentelle Effekte?",
    "result__tab_classic_info": " Bleiben Sie hier oder wechseln Sie zurück zur Hauptversion von ToonMe",
    "result__tab_classic_btn": "Zurück zu ToonMe Classic",

    "processing_text_1": "Vergilbe deine Haut ... 🖌️",
    "processing_text_2": "Karikiere deine Gesichtszüge ...",
    "processing_text_3": "Du siehst aus wie eine Disney-Prinzessin. Nur ein Scherz. Oder doch nicht? ... 🙈",
    "processing_text_4": "Dies ist der Ort, an dem Träume TOON werden 🦄",
    "processing_text_5": "ein-TOONierung deines Fotos!",
    "processing_text_6": "Die Toonifizierung ist in Bearbeitung!",
    "processing_text_7": "Walt Disney hätte das bestimmt gefallen! ✨",

    "collage__select_photo": "Versuche es mit deinem Foto",
    "collage__download": "In HD herunterladen",
    "collage__refresh": "Aktualisieren",
    "collage__more_styles": "Weitere Stile",

    "download_modal__title": "Hör jetzt nicht auf!",
    "download_modal__message": "Wie fühlt es sich an, ein Toon-Star zu sein? Teile den Augenblick deines Ruhms auf Instagram, indem du das Ergebnis, das dir am besten gefällt, postest!",
    "download_modal__message_wait": "Das Ergebnis in HD erstellen ...",
    "download_modal__message_ready": "HD-Version ist fertig, Laden begonnen ..",
    "download_modal__dismiss_button": "Verstanden",
    "download_modal__download_button": "Erneut herunterladen",

    "error__error": "Fehlermeldung",

    "internal_error": "Ein Fehler ist aufgetreten ...",
    "error_overload_webview": "Es tut uns furchtbar leid, aber aufgrund von erhöhten App-Downloads sind unsere Server stark belastet. Bitte versuche es stattdessen über die Registerkarte „Toon Effects“ oder schaue etwas später wieder vorbei. Wir arbeiten intensiv daran, alles in Kürze zu beheben.",
    "error_overload_web": "Es tut uns furchtbar leid, aber aufgrund von erhöhten App-Downloads sind unsere Server stark belastet. Bitte schaue etwas später wieder vorbei. Wir arbeiten intensiv daran, alles in Kürze zu beheben.",

    "rateapp_dialog__title": "Liebst du ToonMe?",
    "rateapp_dialog__message-bold": "Gib der App Sterne und hilf anderen Nutzern, sie auch zu entdecken und zu lieben!",
    "rateapp_dialog__message": "PS: Außerdem hilft es uns dabei, die Bewertungen, die von Serverproblemen beeinflusst wurden, nachdem ToonMe viral wurde, wiederherzustellen. 🙏",
    "rateapp_dialog__rate_button": "Bewerten!",
    "rateapp_dialog__skip_button": "Überspringen",

    "btn_store__title":"<span class=\"btn-store-title\">Im <span class=\"btn-store-name\">{{market}}</span></span><span class=\"btn-store-title\">herunterladen</span>",
    "btn_store__ios":"App Store",
    "btn_store__android":"Google Play",
    "btn_cancel": "Abbrechen und in Standardqualität speichern",
    "btn_clone": "Beware of cheap clone!",
    "btn_contacts_form": "Kontaktieren Sie uns",
    "btn_back": "back",

    "vector_tab_refresh__title": "Fast geschafft!",
    "vector_tab_refresh__subtitle": "Wähle ein Porträt",

    "updated_at": "Mit neuen Effekten aktualisiert am <span class=\"updated-at-date\">{{date}}</span>",

    "warning_clones": "WARNUNG!",
    "warning_clones_link": "HÜTE DICH VOR BILLIGEN KLONEN!",

    "snap_dialog__title": "Vorher-Nachher oder nur TOON?",
    "snap_dialog__message": "Wähle, wie du es auf Snapchat teilst!",
    "snap_dialog__share_button": "Teilen",
    "snap_dialog__back_button": "Zurück",

    "title_contacts_form": "Von den Entwicklern von Photo Lab, der weltweit führenden Fotografie-App für iOS und Android mit 260 Millionen Downloads – und es werden immer mehr ...",

    "toggle_watermark_tooltip__text": "Tippen Sie auf das Logo, um es zu entfernen",
    "toggle_watermark_on": "Logo ein",
    "toggle_watermark_off": "Logo aus",

    "remove_logo_modal__title": "Behalten oder nicht behalten...",
    "remove_logo_modal__message": "Hey, wusstest du, dass das Logo ein Teil des Effektdesigns ist? Wir würden uns freuen, wenn du dich dafür entscheidest, es beizubehalten.",
    "remove_logo_modal__btn_cancel": "Das Logo behalten",
    "remove_logo_modal__btn_remove": "Trotzdem entfernen",

    // v2
    "landing__toonme_on_instagram": "#ToonMe auf Instagram",
  },
};
