import React from 'react';
import AppContext from "../contexts/AppContext";
import {webviewOpenBrowser} from "../utils/webview";
import InstagramPageViewV2 from "./InstagramPageViewV2";

export default class InstagramPage extends React.Component {

  componentDidMount() {
    if (this.props.location.hash) {
      this.handleScroll();
    }
  }

  handleScroll = () => {
    setTimeout(() => {
      const id = this.props.location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }, 0);
  };

  handleClick = (e) => {
    if (window.clientConfig.isWebview) {
      e.preventDefault();

      webviewOpenBrowser(e.currentTarget.getAttribute("href"));
    }
  };

  render() {
    const data = {
      handleClick: this.handleClick
    };

    return <InstagramPageViewV2 {...data} />;
  }
}

InstagramPage.contextType = AppContext;

