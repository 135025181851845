import React from 'react';
import i18n from "../i18n";
import routes from "../routes";
import * as api from "../utils/api";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import Loading from "../components/Loading";
import LoadingV2 from "../components/LoadingV2";
import AppContext from "../contexts/AppContext";
import {generatePath} from "react-router";
import clientStorage from "../utils/client-storage";
import {prefixRouteForSite} from "../utils/etc";

export default class CreatePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      photo: null,
    };
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.file) {
      this.createPhoto(this.props.location.state.file);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
  }

  createPhoto = (file) => {
    const params = {
      site: this.props.site
    };

    if (window.clientConfig.isPro) {
      params.is_pro = 1;
    }

    if (window.clientConfig.isWebview) {
      params.is_webview = 1;
      params.country = window.clientConfig.webviewParams.country;
    }

    if (!clientStorage.getHasProcessedProcessing()) {
      params.is_first = 1;
    }

    api.createPhoto(file, null, params).then((res) => {
      hitEvent(hits.PHOTO_UPLOADED);
      logEvent(userEvents.PHOTO_UPLOADED);
      this.redirectToNextPage(res.photo);
    }).catch((err) => {
      logEvent(userEvents.PHOTO_UPLOAD_FAILED);
      console.error(err);
      this.props.history.replace(prefixRouteForSite(routes.ERROR, this.props.site));
    });
  };

  redirectToNextPage = (photo) => {
    const processingPath = generatePath(
      prefixRouteForSite(routes.PROCESSING, this.props.site),
      {id: photo.id}
    );

    if (window.clientConfig.isWeb) {
      this.props.history.replace(processingPath);
      return;
    }

    const vicmanId = window.clientConfig.webviewParams["aid"]
      || window.clientConfig.webviewParams["vicman_unified_id"];
    const vicmanIdAsNumber = parseInt(vicmanId.substring(vicmanId.length - 2), 16);

    const isHiringPage = window.clientConfig.isWebview
      && (window.clientConfig.locale.toLowerCase().indexOf("jp") >= 0 || window.clientConfig.lang === "ja")
      && (vicmanIdAsNumber / 255 <= 0.03)
      && (clientStorage.getHiringJapanShownAmount() < 2);

    if (isHiringPage) {
      clientStorage.incrementHiringJapanShownAmount();
      this.props.history.replace(generatePath(routes.HIRING_JAPAN), {nextPath: processingPath});
    } else {
      this.props.history.replace(processingPath);
    }
  };

  render() {
    return (window.appConfig.designVersion === "v2" || this.props.site === "love")
      ? <LoadingV2 site={this.props.site} text={i18n.t("loading__uploading")} />
      : <Loading site={this.props.site} text={i18n.t("loading__uploading")} />;
  }
}

CreatePage.contextType = AppContext;