module.exports = {
  "strings": {
    "try_another_photo": "Try another photo",

    "landing__title_part_1": "Woo Hoo!",
    "landing__title_part_2": "It’s time to live your <span>cartoon</span> dream, many times.",
    "landing__subtitle": "Let our AI cartoonize your photos 😜",
    "landing__select_photo_button": "Choose photo",
    "landing__get_the_app_button": "Get the app",
    "loading__uploading": "Uploading...",

    "landing__title_labs": "ToonMe.com LABS",
    "landing__subtitle_labs": "You are at a special experimental spot now. For original styles switch back to the main version below.",
    "landing__labs_back_button": "Back to ToonMe Classic",
    "landing__labs_choice": "Or try our experimental styles on <span>ToonMe&nbsp;LABS</span>",

    "result__labs_tab_title": "Welcome to ToonMe LABS",
    "result__labs_tab_message": "We’re experimenting with new styles and you are in for a sneak peek! Switch back to the original styles at any moment. ;)",
    "result__labs_tab_button": "Let’s try",
    "result__tab_classic_title": "Enjoying experimental effects?",
    "result__tab_classic_info": "Stay here or switch back to the classic version of ToonMe",
    "result__tab_classic_btn": "Back to ToonMe Classic",

    "processing_text_1": "Yellowing your skin...🖌️",
    "processing_text_2": "Cartooning your facial features...",
    "processing_text_3": "You look like a Disney princess. 🤣 Kidding. Or not?..🙈",
    "processing_text_4": "This is the place where dreams come TOON 🦄",
    "processing_text_5": "Fine-TOONing your photo!",
    "processing_text_6": "Toonification is in progress!",
    "processing_text_7": "Walt Disney would have liked it! ✨",

    "collage__select_photo": "Try with your photo",
    "collage__download": "Download in HD",
    "collage__refresh": "Refresh",
    "collage__more_styles": "More styles",

    "banner_title": "We are hiring!",
    "banner_text1": "Expert in <span>Japanese</span> app stores and app monetisation wanted",
    "banner_text2": "Would it be you?",
    "banner_text3": "Contact us at",

    "download_modal__title": "Don’t stop here!",
    "download_modal__message": "How does it feel to be a toon star? Share your moment of fame on Instagram by posting the result you like most!",
    "download_modal__message_wait": "Creating the result in HD…",
    "download_modal__message_ready": "HD version is ready, download started…",
    "download_modal__dismiss_button": "Got it!",
    "download_modal__download_button": "Download again",

    "error__error": "Error",

    "internal_error": "An error occurred...",
    "error_overload_webview": "We are terrible sorry, but our servers experience too much of a load currently due to skyrocketing app downloads.<br />Please, try to use <span class='apptab-feed-icon'></span>Toon Effects tab instead or come back a little later. We are working hard to fix everything ASAP.",
    "error_overload_web": "We are terrible sorry, but our servers experience too much of a load currently due to skyrocketing app downloads.<br />Please, come back a little later. We are working hard to fix everything ASAP.",

    "rateapp_dialog__title": "Love ToonMe?",
    "rateapp_dialog__message-bold": "Give stars to the app & help other users discover and love it too!",
    "rateapp_dialog__message": "PS. It’ll also help us to restore the ratings, affected by server issues after ToonMe went viral.",
    "rateapp_dialog__rate_button": "Rate!",
    "rateapp_dialog__skip_button": "Skip",

    "btn_store__title":"<span class=\"btn-store-title\">Download on the</span><span class=\"btn-store-name\">{{market}}</span>",
    "btn_store__ios":"App Store",
    "btn_store__android":"Google Play",
    "btn_cancel": "Cancel & save <br />in standard quality",
    "btn_clone": "Beware of cheap clone!",
    "btn_contacts_form": "Contact us",
    "btn_back": "back",

    "vector_tab_refresh__title": "Almost there!",
    "vector_tab_refresh__subtitle": "Choose a portrait.",

    "updated_at": "Updated with new effects on <span class=\"updated-at-date\">{{date}}</span>",

    "warning_clones": "WARNING!",
    "warning_clones_link": "BEWARE OF CHEAP CLONES!",

    "snap_dialog__title": "Before-after or just TOON?",
    "snap_dialog__message": "Choose how you snap it!",
    "snap_dialog__share_button": "Share",
    "snap_dialog__back_button": "Back",

    "title_contacts_form": "From the developers of Photo Lab, the world’s leading iOS and Android photography app with 260M downloads – and counting…",

    "toggle_watermark_tooltip__text": "Tap the logo to remove it",
    "toggle_watermark_on": "Logo on",
    "toggle_watermark_off": "Logo off",

    "remove_logo_modal__title": "To keep or not to keep… 🤔",
    "remove_logo_modal__message": "Hey, did you know that the logo is a part of this effect’s design? It’ll make us happy if you choose to keep it.",
    "remove_logo_modal__btn_cancel": "Keep the logo",
    "remove_logo_modal__btn_remove": "Remove anyway",

    "clones_dialog__title": "Join us in fighting back the clone!",
    "clones_dialog__message": "Love ToonMe? Help your peers find and use only original app and fight an evil clone that impersonates us.",
    "clones_dialog__accept_button": "I wanna help 🙌",
    "clones_dialog__skip_button": "Skip",

    "btn_photo_lab": "by Photo Lab",
    "updated_at_labs": "Freshly baked on {{date}}",

    // v2
    "landing__toonme_on_instagram": "#ToonMe on Instagram",
    "likes": "likes",
    "landing__labs_button": "ToonMe LABS",
    "btn_cancelV2": "Cancel",
    "toggle_watermark_on_desktop": "RETURN LOGO",
    "toggle_watermark_off_desktop": "Click the logo to remove it",

    // love
    "result_love__tab_classic_info": "You’re currently playing with Cupid.💘 Stay here or switch back to the classic version of ToonMe.",
    //"landing__labs_choice_title": "Or try our experimental styles on",
    "love__btn_title": "Try our <span>Cupidly Cute</span> specials",
    "result__love_tab_title": "ToonMe kind of love",
    "result__love_tab_message": "Try our AI Valentines made with love for those who've been struck by Cupid's arrow 😋",
    "result__love_tab_button": "Toonme Love",
    "landing__love_choice": "GO TO TOONME CUPID",
    "landing__title_love": "Warning! Criminal cupidity!",
    "landing__subtitle_love": "Let our AI make you look sweet as a cupid. Then spread love and save the world! 🥰",

    "love_processing_text_1": "Turning you into the cutest cupid ever... 😇",
    "love_processing_text_2": "Love is... Just being together 💕",
    "love_processing_text_3": "When it's love, it's love... 💬",
    "love_processing_text_4": "Do these Cupid’s arrows belong to you, huh? 💌",
    "love_processing_text_5": "Love is... sharing popcorn 🍿",
    "love_processing_text_6": "Aww, you look so... cupid 👼",
    "love_processing_text_7": "Loves me... 🌼 Loves me not.. 🌼",
    "love_processing_text_8": "All you need is love. But a little chocolate doesn’t hurt either. 😉",
    "love_processing_text_9": "You are the cream in someone’s coffee ☕",
    "love_processing_text_10": "Knock-knock! Special delivery from Cupid 💕",

    "download_modal_love__message": "Spread love on your social media. Because love will save the world 💓",

    "updated_at_love": "Freshly baked on Feb 12, 2021",

    "back_classic_btn": "toonme <span>classic</span>",
    // \love

    "contact_us_form_show": "contact us",
    "contact_us_form_submit": "send",
    "contact_us_form_completed": "Thank you!",
    "contact_us_form_email__error_empty": "Please enter email to get notified on your group selfie changes.",
    "contact_us_form_email__error_invalid_format": "Oops! Seems you've entered an invalid email address. Please try again.",
    "contact_us_form_name__error_empty": "Please enter name",
    "contact_us_form_background__error_empty": "Please enter background",
    "contact_us_form_title": "Greetings!",
    "contact_us_form_subtitle": "We are looking for an app store marketing expert to help us make ToonMe even more successful in Japan. Both in terms of GUI and monetisation approach. Is it you? Fill in a small form below for us to get to know you and contact for an interview.",
    "contact_us_form_label_name": "Your name:",
    "contact_us_form_label_bg": "Describe your experience and background:",
    "contact_us_form_label_email": "Your email:",
    "contact_us_form_cv": "CV in English (only if you have one)",
    "contact_us_form_close": "return to result",
  },
};
