import React from 'react';
import i18n from "../i18n";
import FileChooseButton from "../components/FileChooseButton";
import {SvgSprite} from "../components/SvgSprite";
import HeaderClassic from "../components/HeaderClassic";

function ErrorPageViewV2({errorMessage, isOverloadPhotolab, handleFileSelected}) {
  return <main className="error-page">
    <HeaderClassic hidden={window.clientConfig.isWebview} />
    <div className="container">
      <div className="error-content">
        <SvgSprite className="icon-cat-error-mob" viewBox="0 0 327 364" icon="icon-cat-error-mob" />
        <h3 className="main-page-title" dangerouslySetInnerHTML={{__html: i18n.t("error__error")}} />
        <p dangerouslySetInnerHTML={{__html: errorMessage}} />
        <FileChooseButton
          hidden={isOverloadPhotolab}
          onFileSelected={handleFileSelected}
          className="btn-upload-foto"
          children={i18n.t("try_another_photo")} />
      </div>
    </div>
    <SvgSprite className="icon-cat-error" viewBox="0 0 1197 734" icon="icon-cat-error" />
  </main>;
}

export default ErrorPageViewV2;
