export const creativeGroups = {
  DISNEY: "disney",
  DISNEY_2D: "disney_2d",
  VECTOR: "vector",
  ARCHER: "archer",
  SIMPSONS: "simpsons",
  CARICATURE: "caricature",
  CARICATURE_2: "caricature_2",
  CARICATURE_3: "caricature_3",
  T4532: "t4532",
  BARBIE: "barbie",
  CARTOON: "cartoon",
  CARTOON_ANIM: "cartoon_anim",
  COLLAGE: "collage",
  CUPID21_1: "cupid21_1",
  CUPID21_2: "cupid21_2",
};

const STATUS_FAILED = -1;
const STATUS_PENDING = 0;
const STATUS_PROCESSING = 1;
const STATUS_PROCESSED = 2;

export function creativeIsProcessed(creative) {
  return creative.status === STATUS_PROCESSED;
}

export function creativeIsFailed(creative) {
  return creative.status === STATUS_FAILED;
}

export function creativeIsProcessing(creative) {
  return creative.status === STATUS_PENDING || creative.status === STATUS_PROCESSING;
}