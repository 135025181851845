import {assetUrl} from "../../utils/etc";
import i18n from "../../i18n";
import React from "react";
import PropTypes from "prop-types";

function LabsTab(props) {
  const jpg = [
    assetUrl(`assets/img/bg/img-labs.jpg`) + " 1x",
    assetUrl(`assets/img/bg/img-labs@2x.jpg`) + " 2x",
    assetUrl(`assets/img/bg/img-labs@3x.jpg`) + " 3x"
  ].join(", ");

  const webp = [
    assetUrl(`assets/img/bg/img-labs.webp`) + " 1x",
    assetUrl(`assets/img/bg/img-labs@2x.webp`) + " 2x",
    assetUrl(`assets/img/bg/img-labs@3x.webp`) + " 3x"
  ].join(", ");

  return <div className="tab-custom tab-lab container">
    <div className="bg-image-container">
      <picture>
        <source srcSet={webp} type="image/webp" />
        <img srcSet={jpg} alt="Demo" />
      </picture>
    </div>
    <div className="tab-lab-content">
      <h3 dangerouslySetInnerHTML={{__html: i18n.t("result__labs_tab_title")}} />
      <p dangerouslySetInnerHTML={{__html: i18n.t("result__labs_tab_message")}} />
      <button
        dangerouslySetInnerHTML={{__html: i18n.t("result__labs_tab_button")}}
        onClick={props.onButtonClick}
      />
    </div>
  </div>;
}

LabsTab.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
};

export default LabsTab;