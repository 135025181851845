module.exports = {
  "strings": {
    "try_another_photo": "尝试另一张照片",

    "landing__title_part_1": "哇喔！",
    "landing__title_part_2": "是时候多次实现你的卡通梦想了。",
    "landing__subtitle": "只需将你的照片交给AI就行了😜",
    "landing__select_photo_button": "选择照片",
    "landing__get_the_app_button": "获取应用",
    "loading__uploading": "上传",

    "landing__title_labs": "ToonMe.com LABS",
    "landing__subtitle_labs": "你现在在特别试验点。要使用原有风格请在下面切换回主版本。",
    "landing__labs_back_button": "回到 ToonMe Classic",
    "landing__labs_choice": "或在<span>ToonMe&nbsp;LABS</span>上尝试我们的实验风格",

    "result__labs_tab_title": "Welcome to ToonMe LABS",
    "result__labs_tab_message": "我们在试验新的风格，你有资格先睹为快！ 随时换回原来的风格。",
    "result__labs_tab_button": "让我们试试吧",
    "result__tab_classic_title": "喜欢试验版特效吗？",
    "result__tab_classic_info": "留在这里或者换回 ToonMe 的主版本",
    "result__tab_classic_btn": "回到 ToonMe Classic",

    "processing_text_1": "正在让你的皮肤变黄……🖌️",
    "processing_text_2": "正在卡通化你的面部特征……",
    "processing_text_3": "你看起来像一位迪士尼公主，开玩笑的。或者是真的？……🙈",
    "processing_text_4": "这是梦想成为卡通的地方 🦄",
    "processing_text_5": "正在卡通化调整你的照片！",
    "processing_text_6": "正在进行卡通化！",
    "processing_text_7": "华特·迪士尼一定会喜欢的！✨",

    "collage__select_photo": "用你的照片试试看吧",
    "collage__download": "下载高清版本",
    "collage__refresh": "刷新",
    "collage__more_styles": "更多风格",

    "download_modal__title": "别在这里停下来！",
    "download_modal__message": "当卡通明星是什么感觉？将你最喜欢的结果发到Instagram上，分享你的成名时刻吧！",
    "download_modal__message_wait": "正在创建高清版本的结果……",
    "download_modal__message_ready": "高清版准备好了，已开始下载……",
    "download_modal__dismiss_button": "知道了",
    "download_modal__download_button": "重新下载",

    "error__error": "错误",

    "internal_error": "发生了错误……",
    "error_overload_webview": "我们深表歉意，由于应用下载量增加，我们的服务器负载很高。请尝试改用Toon Effects选项卡，或稍后再回来查看。我们正在努力尽快修复所有问题。",
    "error_overload_web": "我们深表歉意，由于应用下载量增加，我们的服务器负载很高。请稍后再回来查看。我们正在努力尽快修复所有问题。",

    "rateapp_dialog__title": "爱上ToonMe了吗？",
    "rateapp_dialog__message-bold": "请为应用评分，帮助其他用户也发现并爱上它！",
    "rateapp_dialog__message": "注：这也会帮助我们恢复评分，因为在ToonMe走红后受到了服务器问题的影响。 🙏",
    "rateapp_dialog__rate_button": "评分！",
    "rateapp_dialog__skip_button": "跳过",

    "btn_store__title":"<span class=\"btn-store-title\">在<span class=\"btn-store-name\">{{market}}</span>下载</span>",
    "btn_store__ios":"App Store",
    "btn_store__android":"Google Play",
    "btn_cancel": "取消并以标准质量保存",
    "btn_clone": "Beware of cheap clone!",
    "btn_contacts_form": "联系我们",
    "btn_back": "back",

    "vector_tab_refresh__title": "就快好了！",
    "vector_tab_refresh__subtitle": "选择一副肖像",

    "updated_at": "{{date}}更新了新特效",

    "warning_clones": "警告！",
    "warning_clones_link": "小心廉价的山寨版本",

    "snap_dialog__title": "之前之后对比，还是仅卡通化？",
    "snap_dialog__message": "选择你如何抓拍它！",
    "snap_dialog__share_button": "分享",
    "snap_dialog__back_button": "返回",

    "title_contacts_form": "由 Photo Lab 的开发人员诚意献上，世界领先的 iOS 和 Android 照片应用。2.6亿次下载，持续增加中……",

    "toggle_watermark_tooltip__text": "点击水印以去除",
    "toggle_watermark_on": "标识启用/关闭",
    "toggle_watermark_off": "标识启用/关闭",

    "remove_logo_modal__title": "保留还是移除……",
    "remove_logo_modal__message": "嘿，你知道那个标识是这个特效设计的一部分吗？如果你保留它，我们会很开心。",
    "remove_logo_modal__btn_cancel": "保留标识",
    "remove_logo_modal__btn_remove": "坚持移除",

    // v2
    "landing__toonme_on_instagram": "Instagram 上搜 #ToonMe",
  },
};
