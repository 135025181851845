import * as amplitude from "amplitude-js";
import * as api from "./api";
import {isLoveSiteByLocation} from "./etc";

let webviewParamsIsCommited = false;

const userProperties = {
  client_type: window.clientConfig.isWebview ? "webview" : (window.clientConfig.isWebMobile ? "mobile" : "desktop"),
  is_webview: window.clientConfig.isWebview,
  is_mobile: window.clientConfig.isWebMobile,
  platform_browser_name: window.clientConfig.platform.name,
  platform_browser_version: window.clientConfig.platform.version,
  platform_os: window.clientConfig.platform.os,
  screen_w: window.screen.width,
  screen_h: window.screen.height,
  viewport_w: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
  viewport_h: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
  locale: window.clientConfig.locale,
  is_pro: window.clientConfig.isPro,
  split_group: window.clientConfig.splits.groupId,
};

if (window.clientConfig.isWebview) {
  const osName = window.clientConfig.isWebviewAndroid
    ? "Android"
    : "iOS";

  userProperties.os_version = `${osName} ${window.clientConfig.webviewParams.os_version}`;
  userProperties.native_app_build = `${osName} ${window.clientConfig.webviewParams.version_code}`;
}

const userPropertiesCommitWaited = Object.keys(userProperties);

if (window.appConfig.isDebug) {
  console.log("initial user properties", userProperties);
}

if (window.appConfig.amplitude.isEnabled) {
  amplitude.getInstance().init(window.appConfig.amplitude.key);
  amplitude.getInstance().setUserProperties({...userProperties});
}

export const userEvents = {
  // получили событие webiew_tab_selected от нативного приложения
  // параметры:
  // os: android, ios или other
  WEBVIEW_TAB_FOCUS: "webview_tab_focus",

  // визит на главную страницу
  PAGE_INDEX: "page_index",

  // визит на страницу результата
  // параметры:
  // photo_id (наш идентификатор фото)
  // is_owner (владелец/реферал)
  // ref (канал шаринга или unspecified)
  PAGE_RESULT: "page_result",

  // клик на кнопке "get the app"
  GET_APP_CLICK: "get_app_click",

  // юзер нажал на "попробовать другое фото"
  // параметры: page (страница: create, result, error)
  HOME_BUTTON_CLICK: "home_button_click",

  // юзер выбрал фото
  // параметры: page (страница: index, error, result)
  PHOTO_SELECT: "photo_select",

  // юзер успешно загрузил фото
  PHOTO_UPLOADED: "photo_uploaded",

  // юзер неуспешно загрузил фото
  PHOTO_UPLOAD_FAILED: "photo_upload_failed",

  // успешно закончился процессинг всех обработок
  // elapsed_time_ms (время затраченое юзером на ожидание с момент загрузки фото, в миллисекундах)
  PROCESSING_PROCESSED: "processing_processed",

  // неуспешно закончился процессинг всех обработок
  // elapsed_time_ms (время затраченое юзером на ожидание с момент загрузки фото, в миллисекундах)
  PROCESSING_FAILED: "processing_failed",

  // юзер сменил таб на результате
  // параметры:
  // group (группа)
  // prev_group (прошлая группа, с которой переключили)
  TAB_SELECT: "tab_select",

  // параметры:
  // group (название группы, для которой сделан рефреш)
  // template_id (только группа vector, идентификатор шаблона, который юзер выбрал)
  TAB_REFRESH: "tab_refresh",

  // параметры:
  // group (название группы, которой сделан рефреш)
  // photo_id (идентификатор фото)
  // creative_id (идентификатор обработки)
  // template_id (идентификатор шаблона)
  TAB_FAILED: "tab_failed",

  // параметры:
  // photo_id (наш идентификатор фото)
  // creative_id (наш идентификатор обработки)
  // template_id (наш идентификатор шаблона)
  // group (группа креативов)
  // default_tab_group (группа креативов выбранная при загрузке страницы)
  CREATIVE_VIEW: "creative_view",

  // юзер кликнул на кнопку шаринга
  // параметры:
  // photo_id (наш идентификатор фото)
  // creative_id (наш идентификатор обработки)
  // is_video (true/false)
  // provider (название поток шаринга)
  SHARE: "share",

  // юзер кликнул на кнопку скачать
  // параметры:
  // photo_id (наш идентификатор фото)
  // creative_id (наш идентификатор обработки)
  // template_id (наш идентификатор шаблона)
  // group (группа креативов)
  // default_tab_group (группа креативов выбранная при загрузке страницы)
  DOWNLOAD: "download",

  // инициировано скачивание файла
  // параметры: см. параметры события download
  DOWNLOAD_START: "download_start",

  RATEAPP_DIALOG_SHOW: "rateapp_dialog_show",
  RATEAPP_DIALOG_RATE: "rateapp_dialog_rate",
  RATEAPP_DIALOG_SKIP: "rateapp_dialog_skip",
  CLONES_DIALOG_SHOW: "clones_dialog_show",
  CLONES_DIALOG_ACCEPT: "clones_dialog_accept",
  CLONES_DIALOG_SKIP: "clones_dialog_skip",
};

export const hits = {
  WEBVIEW_TAB_FOCUS: 8227,
  GET_APP_CLICK: 8228,
  PAGE_INDEX_WEB_DESKTOP: 8229,
  PAGE_INDEX_WEB_MOBILE: 8230,
  PAGE_OFFICIAL: 8248,
  PAGE_HIRING_JAPAN: 8279,
  PHOTO_SELECT: 8231,
  PHOTO_UPLOADED: 8232,
  PROCESSING_PROCESSED: 8233,
  PROCESSING_PROCESSED_FIRST: 8256,
  PROCESSING_FAILED: 8234,
  PROCESSING_FAILED_PHOTOLAB: 8235,
  PROCESSING_FAILED_ON_RESULT_PAGE: 8241,
  INTERNAL_ERROR_VIEW: 8236,
  RATEAPP_DIALOG_SHOW: 8238,
  RATEAPP_DIALOG_RATE: 8239,
  RATEAPP_DIALOG_SKIP: 8240,
  RATEAPP_DIALOG_INAPP_RESPONSE_0: 8247,
  RATEAPP_DIALOG_INAPP_RESPONSE_1: 8244,
  RATEAPP_DIALOG_INAPP_RESPONSE_2: 8245,
  RATEAPP_DIALOG_INAPP_RESPONSE_3: 8246,
  SHARE_CLICK_SNAPCHAT: 8250,
  SHARE_CLICK_FACEBOOK: 8251,
  SHARE_CLICK_INSTAGRAM: 8252,
  CLONES_DIALOG_SHOW: 8253,
  CLONES_DIALOG_ACCEPT: 8254,
  CLONES_DIALOG_SKIP: 8255,
  ERROR_PAGE_PHOTOLAB_OVERLOAD_MESSAGE: 8243,
  REMOVE_LOGO_CANCEL: 8260,
  REMOVE_LOGO_REMOVE: 8261,
  NATIVE_ADS_SHOW_REQUEST: 8262,
  NATIVE_ADS_SHOWN: 8263,
  HIRING_EMAIL_CLICK: 8280,
  PAGE_INDEX_SQUARE_VISIT: 8281,
  PAGE_INDEX_CIRCLE_VISIT: 8282,
  PAGE_INDEX_SQUARE_CLICK: 8283,
  PAGE_INDEX_CIRCLE_CLICK: 8284,
};

export function hitEvent(id) {
  if (window.appConfig.isDebug) {
    console.info("hitEvent", id);
  }

  if (window.appConfig.hits.isEnabled) {
    const url = `${window.appConfig.hits.endpoint}?id=${id}&r=${Date.now()}`;

    if (window.navigator.sendBeacon) {
      window.navigator.sendBeacon(url);
    } else {
      window.axios.post(url).then(() => {/* dummy */}).catch(console.error);
    }
  }
}

export function logEvent(eventId, eventParams, cb) {
  eventParams = eventParams || {};

  if (window.location.pathname.indexOf("/labs") === 0) {
    eventParams.is_labs = true; // deprecated
    eventParams.site = "labs";
  }

  if (isLoveSiteByLocation()) {
    eventParams.site = "love";
  }

  if (window.appConfig.isDebug) {
    console.info("logEvent", eventId, eventParams);
  }

  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().logEvent(eventId, eventParams || {}, cb);
  }

  if (window.appConfig.analytics.isEnabled) {
    let userParams = undefined;
    if (userPropertiesCommitWaited.length > 0) {
      userParams = {};
      userPropertiesCommitWaited.forEach((key) => userParams[key] = userProperties[key]);
      userPropertiesCommitWaited.length = 0;
    }

    let webviewParams = undefined;
    if (window.clientConfig.isWebview && !webviewParamsIsCommited) {
      webviewParamsIsCommited = true;
      webviewParams = window.clientConfig.webviewParams;
    }

    api.logEvent(eventId, eventParams, userParams, webviewParams)
      .catch(console.error);
  }
}

export function setUserProperty(key, value) {
  if (window.appConfig.isDebug) {
    console.log("setUserProperty", key, value);
  }

  userProperties[key] = value;
  userPropertiesCommitWaited.push(key);

  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().identify(new amplitude.Identify().set(key, value));
  }
}