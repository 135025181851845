export function debounce(name, delay, func) {
  window.appDebounceTimers = window.appDebounceTimers || {};
  clearTimeout(window.appDebounceTimers[name]);
  window.appDebounceTimers[name] = setTimeout(func, delay);

  return window.appDebounceTimers[name];
}

export function assetUrl(path, withSuffix = true) {
  const suffix = process.env.REACT_APP_ASSETS_VERSION
    ? ("?" + process.env.REACT_APP_ASSETS_VERSION)
    : "";

  return `${window.appConfig.paths.assets}/${path}${withSuffix ? suffix : ""}`;
}

export function prefixRouteForSite(route, site) {
  // love в веб на отдельном домене, потому для него не добавляем префикс
  if (window.clientConfig.isWeb && site === "love" && window.location.hostname.indexOf("cupid.") === 0) {
    return route;
  }

  return (site && site !== "classic") ? ("/" + site + route) : route;
}

export function isLoveSiteByLocation() {
  return window.location.pathname.indexOf("/love") === 0
    || window.location.hostname.indexOf("cupid.") === 0;
}

export function isLabsSiteByLocation() {
  return window.location.pathname.indexOf("/labs") === 0;
}

export function when(key, map) {
  return map.hasOwnProperty(key) ? map[key] : undefined;
}