import React from "react";
import i18n from "../../i18n";
import Modal from "../../components/Modal";
import {SvgCloudLeft, SvgCloudRight, SvgCloudTop, SvgModalBackground} from "../../ui/svg";
import {hitEvent, hits} from "../../utils/log";
import PropTypes from "prop-types";

class RemoveLogoModal extends Modal {

  handleCancelButtonClick = () => {
    hitEvent(hits.REMOVE_LOGO_CANCEL);

    this.props.onShowButtonClick();
    this.dismiss();
  };

  handleRemoveButtonClick = () => {
    hitEvent(hits.REMOVE_LOGO_REMOVE);

    this.props.onHideButtonClick();
    this.dismiss();
  };

  renderModal() {
    return <div className="modal-container">
      <div className="modal modal-remove-logo">
        <SvgModalBackground />
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("remove_logo_modal__title")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("remove_logo_modal__message")}} />
        <div className="btns-container">
          <div className="btns-container_share">
            <button
              className="btn-upload-foto"
              children={i18n.t("remove_logo_modal__btn_cancel")}
              onClick={this.handleCancelButtonClick} />
          </div>
          <button
            className="btn-upload-foto download"
            dangerouslySetInnerHTML={{__html: i18n.t("remove_logo_modal__btn_remove")}}
            onClick={this.handleRemoveButtonClick} />
        </div>
      </div>
      <SvgCloudTop />
      <SvgCloudRight />
      <SvgCloudLeft />
    </div>;
  }
}

RemoveLogoModal.propTypes = {
  onShowButtonClick: PropTypes.func.isRequired,
  onHideButtonClick: PropTypes.func.isRequired,
};

export default RemoveLogoModal;