import React from "react";
import i18n from "../../i18n";
import {
  SvgCloudLeft,
  SvgCloudRight,
  SvgCloudTop,
  SvgModalBackground
} from "../../ui/svg";
import RemoveLogoModalDefault from "../result/RemoveLogoModal";

export default class RemoveLogoModal extends RemoveLogoModalDefault {
  renderModal() {
    return <div className="modal-container">
      <div className="modal modal-remove-logo">
        <SvgModalBackground />
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("remove_logo_modal__title")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("remove_logo_modal__message")}} />
        <div className="btns-container">
          <div className="btns-container_share">
            <button
              className="btn-upload-foto"
              children={i18n.t("remove_logo_modal__btn_cancel")}
              onClick={this.handleCancelButtonClick} />
          </div>
          <button
            className="btn-upload-foto download"
            dangerouslySetInnerHTML={{__html: i18n.t("remove_logo_modal__btn_remove")}}
            onClick={this.handleRemoveButtonClick} />
        </div>
      </div>
      <SvgCloudTop />
      <SvgCloudRight />
      <SvgCloudLeft />
    </div>;
  }
}
