import React from 'react';
import Slider from "react-slick";
import {Link} from "react-router-dom";
import i18n from "../i18n";
import routes from "../routes";
import {INSTAGRAM_MENTIONS} from "../utils/constants";
import {SvgSprite} from "../components/SvgSprite";
import {ExternalUrl} from "./IndexPage";

function InstagramPageViewV2({handleClick}) {
  return <main className="instagram-page">
      <div className="container">
        {INSTAGRAM_MENTIONS.map((data, key) => <FeedView key={key} {...data} handleClick={handleClick} />)}
      </div>
      <Link to={routes.INDEX} className="btn-back">
        <SvgSprite viewBox="0 0 32 54" icon="icon-back" />
        <span dangerouslySetInnerHTML={{__html: i18n.t("btn_back")}} />
      </Link>
    </main>;
}

export default InstagramPageViewV2;

function FeedView({username, url, images, avatar, likes, handleClick}) {
  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return <div className="user-post" id={username}>
    <ExternalUrl url={url} onClick={handleClick}>
      <section className="wr-slider-images">
        <Slider {...sliderSettings}>
          {images.map((url, key) => <div key={key} className="slide"><img src={url} alt="" /></div>)}
        </Slider>
      </section>
      <div className="user-info">
        <div className="user-name">
          <img src={avatar} alt="" />
          <p>{username}</p>
        </div>
        <div className="user-likes">
          <p>{likes} <span>{i18n.t("likes")}</span></p>
        </div>
      </div>
    </ExternalUrl>
  </div>;
}