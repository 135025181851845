import React from 'react';
import i18n from "../i18n";
import routes from "../routes";
import {hitEvent, hits} from "../utils/log";
import {webviewOpenBrowser} from "../utils/webview";
import AppContext from "../contexts/AppContext";
import {Link} from "react-router-dom";

const PLAY_MARKET_LINK = "https://play.google.com/store/apps/details?id=com.vicman.toonmeapp&referrer=apptoonmecom";
const APPSTORE_LINK = "https://apps.apple.com/us/app/toonme-cartoon-photo-editor/id1508120751";

export default class OfficialPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    hitEvent(hits.PAGE_OFFICIAL);

    i18n.reloadResources(null, ["official"], () =>
      this.setState({isLoading: false})
    )
  }

  handleAppStorClick = (e) => {
    if (window.clientConfig.isWebview) {
      e.preventDefault();
      webviewOpenBrowser(APPSTORE_LINK);
    }
  };

  handlePlayMarketClick = (e) => {
    if (window.clientConfig.isWebview) {
      e.preventDefault();
      webviewOpenBrowser(PLAY_MARKET_LINK);
    }
  };

  render() {
    if (this.state.isLoading) return null;

    return <div className="article-page">
      <div className="container">
        <h1><Link to={routes.INDEX} className="toonme-app-link link-back">TOONME.COM</Link></h1>
        <h3>{i18n.t("official:title")}</h3>
        <p dangerouslySetInnerHTML={{__html: i18n.t("official:text_1")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("official:text_2")}} />
          <div className="btns-market-container">
            <a className="btn-store" onClick={this.handleAppStorClick} href={APPSTORE_LINK} target="_blank" rel="noopener noreferrer">
              <svg viewBox="0 0 66 80">
                <g fill="none" fillRule="evenodd">
                  <g fill="#000" fillRule="nonzero">
                    <g>
                      <g>
                        <path d="M21.702 80C9.826 79.931.003 55.626.003 43.248c0-20.219 15.129-24.645 20.96-24.645 2.627 0 5.432 1.034 7.907 1.95 1.73.638 3.52 1.297 4.515 1.297.596 0 2-.56 3.24-1.053 2.643-1.055 5.933-2.366 9.763-2.366h.023c2.86 0 11.533.63 16.747 8.48l1.221 1.84-1.757 1.33c-2.511 1.9-7.093 5.365-7.093 12.23 0 8.129 5.189 11.256 7.682 12.76 1.1.663 2.239 1.347 2.239 2.844 0 .978-7.78 21.964-19.078 21.964-2.764 0-4.718-.833-6.442-1.568-1.744-.744-3.248-1.385-5.734-1.385-1.26 0-2.854.598-4.541 1.232C27.35 79.02 24.74 80 21.779 80h-.077zM47.585 0c.294 10.637-7.293 18.016-14.871 17.553C31.465 9.065 40.291 0 47.585 0z" transform="translate(-196 -2409) translate(132 2357) translate(64 52)"/>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <div className="btn-store-content">
                <span className="btn-store-title">{i18n.t("official:download_on")}</span>
                <span className="btn-store-name">{i18n.t("official:app_store")}</span>
              </div>
            </a>
            <a className="btn-store" onClick={this.handlePlayMarketClick} href={PLAY_MARKET_LINK} target="_blank" rel="noopener noreferrer">
              <svg viewBox="0 0 64 72">
                <g fill="none" fillRule="evenodd">
                  <g fill="#000" fillRule="nonzero">
                    <g>
                      <g>
                        <path d="M9.741.918C7.881-.2 5.666-.3 3.733.63l32.405 32.394 10.595-10.619L9.741.918zM.756 3.628C.347 4.493.127 5.454.127 6.442v59.257c0 .993.221 1.94.635 2.784l32.391-32.466L.756 3.63zm35.386 35.375L3.79 71.43c.858.396 1.772.57 2.684.57 1.126 0 2.25-.27 3.267-.88l37-21.52-10.599-10.597zm24.68-8.412c-.013-.009-.027-.017-.04-.024L50.512 24.6 39.124 36.012l11.393 11.39 10.304-5.993c1.91-1.157 3.05-3.18 3.05-5.41s-1.14-4.252-3.05-5.408z" transform="translate(-883 -2419) translate(812 2357) translate(71 62)"/>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <div className="btn-store-content">
                <span className="btn-store-title">{i18n.t("official:download_on")}</span>
                <span className="btn-store-name">{i18n.t("official:google_play")}</span>
              </div>
            </a>
          </div>
          <p dangerouslySetInnerHTML={{__html: i18n.t("official:text_3")}} />
          <p dangerouslySetInnerHTML={{__html: i18n.t("official:text_4")}} />
          <p dangerouslySetInnerHTML={{__html: i18n.t("official:text_5")}} />
          <p dangerouslySetInnerHTML={{__html: i18n.t("official:text_6")}} />
          <p className="text-notice" dangerouslySetInnerHTML={{__html: i18n.t("official:text_7")}} />
          <p>{i18n.t("official:back_to")} <Link to={routes.INDEX} className="toonme-app-link">ToonMe.com</Link></p>
      </div>
    </div>;
  }
}

OfficialPage.contextType = AppContext;
