import ImageView from "../../components/ImageView";
import React from "react";
import PropTypes from "prop-types";

export default class CreativeView extends React.Component{

  constructor(props) {
    super(props);

    this.onLoadedCalled = false;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.creative.id !== this.props.creative.id) {
      this.onLoadedCalled = false;
    }
  }

  onLoaded = () => {
    // на video.onCanPlay срабатывает при каждом проигрывании
    if (this.onLoadedCalled === false) {
      this.props.onLoaded(this.props.creative);
      this.onLoadedCalled = true;
    }
  };

  renderImage = () => {
    return <ImageView
      image={this.props.creative.file}
      onImageLoaded={this.onLoaded}
      backgrounded
    />;
  };

  renderVideo = () => {
    return <video
      key={this.props.creative.file.url}
      playsInline
      muted
      loop
      autoPlay
      controls={false}
      onCanPlay={this.onLoaded}>
      <source type="video/mp4" src={this.props.creative.file.url} />
    </video>;
  };

  render() {
    return this.props.creative.file.extension.toLowerCase() === "mp4"
      ? this.renderVideo()
      : this.renderImage();
  }
}

CreativeView.propTypes = {
  creative: PropTypes.object.isRequired,
  onLoaded: PropTypes.func.isRequired,
};