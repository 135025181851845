import React from "react";
import i18n from "../i18n";
import {Link} from "react-router-dom";
import routes from "../routes";
import AppContext from "../contexts/AppContext";
import IndexPage from "./IndexPage";
import {SvgSprite} from "../components/SvgSprite";
import FileChooseButton from "../components/FileChooseButton";
import HeaderClassic from "../components/HeaderClassic";
import {assetUrl} from "../utils/etc";
import {PLAY_MARKET_LINK, APPSTORE_LINK, MENTIONS, INSTAGRAM_MENTIONS} from "../utils/constants";
import {ExternalUrl, LogoView} from "./IndexPage";
import Slider from "react-slick";
import {getCurrentLocale} from "../i18n";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import {formatClassicUpdatedAt} from "../utils/date";

export default class IndexPageV2 extends IndexPage {

  renderWebMobileButton = () => {
    return <button
        className="btn-upload-foto btn-get-app highlight"
        children={i18n.t("landing__get_the_app_button")}
        onClick={this.handleGetAppButtonClick} />;
  };

  renderWebDesktopCircledButton = () => {
    const lang = getCurrentLocale().substring(0, 2).toLowerCase();

    return <FileChooseButton
      onClick={() => hitEvent(hits.PAGE_INDEX_CIRCLE_CLICK)}
      onFileSelected={this.handleFileSelected}
      className="btn-upload-foto-desktop">
      <SvgSprite viewBox="0 0 228 228" icon={`btn-upload-lang-${lang}`} />
      <span className="btn-icon" />
      <span className="btn-upload-foto-desktop-title" dangerouslySetInnerHTML={{__html:i18n.t("landing__select_photo_button")}} />
    </FileChooseButton>;
  };

  renderWebDesktopSquaredButton = () => {
    return <FileChooseButton
      onClick={() => hitEvent(hits.PAGE_INDEX_SQUARE_CLICK)}
      onFileSelected={this.handleFileSelected}
      className="btn-upload-v2">
      <span className="btn-icon-plus" />
      <span className="" dangerouslySetInnerHTML={{__html:i18n.t("landing__select_photo_button")}} />
    </FileChooseButton>
  };

  renderMain = () => {
    const sliderSettings = {
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const indexImagesSplitGroups = {
      "default": [148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160],
    };

    const indexImages = indexImagesSplitGroups["default"];

    const titleContactsFormText = i18n.t("title_contacts_form")
      .replace("Photo Lab", "<a href='https://y3nay.app.goo.gl/toonmecompl' target='_blank'>Photo Lab</a>");

    return <main className="main-page">
      <HeaderClassic hidden={window.clientConfig.isWebview} />
      <div className="container">
        <div className="main-page-content">
          <div className="main-page-title-container">
            <h2 className="main-page-title" dangerouslySetInnerHTML={{__html:i18n.t("landing__title_part_1")}} />
            <h2 className="main-page-title" dangerouslySetInnerHTML={{__html:i18n.t("landing__title_part_2")}} />

            <p className="main-page-subtitle">{i18n.t("landing__subtitle")}</p>
          </div>

          {/*{window.clientConfig.isWebDesktop && isSquareButton && this.renderWebDesktopSquaredButton()}*/}

          <section className="wr-slider-images">
            <Slider {...sliderSettings}>
              {indexImages.map((number) => <SlideView key={number} number={number} />)}
            </Slider>
            {window.clientConfig.isWebview && this.renderWebviewButton()}
            {window.clientConfig.isWebMobile && this.renderWebMobileButton()}
            {window.clientConfig.isWebDesktop && this.renderWebDesktopCircledButton()}

            <div className="square" />
          </section>

          {/* <div className="love-btn-container" hidden={window.clientConfig.isWebview}>*/}
          {/*  <p dangerouslySetInnerHTML={{__html: i18n.t("love__btn_title")}} />*/}
          {/*  <button*/}
          {/*    className="btn-love"*/}
          {/*    dangerouslySetInnerHTML={{__html: i18n.t("landing__love_choice")}}*/}
          {/*    onClick={this.handleLoveButtonClick} />*/}
          {/*  <SvgHeartIcon classNameIcon="icon-heart icon-heart-1" />*/}
          {/*  <SvgHeartIcon classNameIcon="icon-heart icon-heart-2" />*/}
          {/*  <SvgHeartIcon classNameIcon="icon-heart icon-heart-3" />*/}
          {/*  <SvgHeartIcon classNameIcon="icon-heart icon-heart-4" />*/}
          {/*  <SvgHeartIcon classNameIcon="icon-heart icon-heart-5" />*/}
          {/*</div> */}

          <div className="main-page-labs-container" hidden={window.clientConfig.isWebMobile}>
            <LabsView />
            <div className="main-page-labs-info">
              <h2 className="main-page-title" dangerouslySetInnerHTML={{__html:i18n.t("landing__labs_choice")}} />
              <Link to={routes.LABS_INDEX} className="btn-go-labs" dangerouslySetInnerHTML={{__html: i18n.t("landing__labs_button")}} />
            </div>
          </div>

          <p className="updated-at">
            <span className="updated-at-title" dangerouslySetInnerHTML={{__html: i18n.t("updated_at", {date: formatClassicUpdatedAt()})}} />
          </p>
        </div>

        <MentionView />

        <div className="instagram" hidden={window.clientConfig.isWebview}>
          <h2 className="main-page-title" dangerouslySetInnerHTML={{__html:i18n.t("landing__toonme_on_instagram")}} />
          
          <div className="posts-list">
            {INSTAGRAM_MENTIONS.map((data, key) => {
              return <div key={key} className="post">
                <div className="post-image">
                  <ExternalUrl url={data.url}>
                    <img src={data.images[0]} alt="" />
                  </ExternalUrl>
                </div>
                <div className="user-info">
                  <div className="user-name">
                    <img src={data.avatar} alt="" />
                    <p>{data.username}</p>
                  </div>
                  <div className="user-likes">
                    <p>{data.likes}</p>
                    <SvgSprite viewBox="0 0 24 24" icon="icon-like" />
                  </div>
                </div>
              </div>;}
            )}
          </div>
        </div>

        <div className="btns-market-container" hidden={window.clientConfig.isWebview}>
          <ExternalUrl className="btn-store btn-store-apple" url={APPSTORE_LINK} onClick={() => logEvent(userEvents.GET_APP_CLICK, {type: "footer_v2"})}>
            <SvgSprite viewBox="0 0 66 80" icon="icon-ios-store" />
            <div className="btn-store-content" dangerouslySetInnerHTML={{__html: i18n.t("btn_store__ios")}} />
          </ExternalUrl>
          <ExternalUrl className="btn-store" url={PLAY_MARKET_LINK} onClick={() => logEvent(userEvents.GET_APP_CLICK, {type: "footer_v2"})}>
            <SvgSprite viewBox="0 0 64 72" icon="icon-android-store" />
            <div className="btn-store-content" dangerouslySetInnerHTML={{__html: i18n.t("btn_store__android")}} />
          </ExternalUrl>
        </div>

        <p hidden={window.clientConfig.isWebview}
           className="title-contacts-form"
           dangerouslySetInnerHTML={{__html: titleContactsFormText}} />

        <footer className="footer" hidden={window.clientConfig.isWebview}>
          <a href="mailto:contact@toonme.com" className="btn-contacts-form btn-contacts-form-footer" dangerouslySetInnerHTML={{__html: i18n.t("btn_contacts_form")}} />
          <ExternalUrl url={this.getPrivacyLink()} children="Privacy Policy" className="btn-privacy" />
        </footer>
      </div>
      <SvgSprite className="line-main-mob" viewBox="0 0 360 216" icon="line-main-mob" />
      <SvgSprite className="line-main" viewBox="0 0 1202 372" icon="line-main" />
    </main>;
  }
}

IndexPageV2.contextType = AppContext;

export function SlideView({number}) {
  const png = [
    assetUrl(`assets/img/slider/img-${number}.png`) + " 1x",
    assetUrl(`assets/img/slider/img-${number}@2x.png`) + " 2x"
  ].join(", ");

  const webp = [
    assetUrl(`assets/img/slider/img-${number}.webp`) + " 1x",
    assetUrl(`assets/img/slider/img-${number}@2x.webp`) + " 2x"
  ].join(", ");

  return <div className="slide-container">
    <div className="slide">
      <picture>
        <source srcSet={webp} type="image/webp" />
        <img srcSet={png} alt="Demo" />
      </picture>
    </div>
  </div>;
}

export function LabsView() {
  const pngMob = [
    assetUrl(`assets/img/bg/img_labs_mob.png`) + " 1x",
    assetUrl(`assets/img/bg/img_labs_mob@2x.png`) + " 2x",
    assetUrl(`assets/img/bg/img_labs_mob@3x.png`) + " 3x"
  ].join(", ");

  const webpMob = [
    assetUrl(`assets/img/bg/img_labs_mob.webp`) + " 1x",
    assetUrl(`assets/img/bg/img_labs_mob@2x.webp`) + " 2x",
    assetUrl(`assets/img/bg/img_labs_mob@3x.webp`) + " 3x"
  ].join(", ");

  const jpg = [
    assetUrl(`assets/img/bg/img_labs.jpg`) + " 1x",
    assetUrl(`assets/img/bg/img_labs@2x.jpg`) + " 2x",
    assetUrl(`assets/img/bg/img_labs@3x.jpg`) + " 3x"
  ].join(", ");

  const webp = [
    assetUrl(`assets/img/bg/img_labs.webp`) + " 1x",
    assetUrl(`assets/img/bg/img_labs@2x.webp`) + " 2x",
    assetUrl(`assets/img/bg/img_labs@3x.webp`) + " 3x"
  ].join(", ");

  return <picture>
    <source media="(max-width: 900px)" srcSet={webpMob} type="image/webp" />
    <source media="(max-width: 900px)" srcSet={pngMob} />
    <source srcSet={webp} type="image/webp" />
    <img srcSet={jpg} alt="Demo" />
  </picture>;
}

export class MentionView extends React.Component {
  render() {
    if (window.clientConfig.isWebview) {
      return null;
    }

    const sliderSettings = {
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 10000,
          settings: 'unslick'
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: 2,
          }
        }
      ],
    };

    const links = Object.keys(MENTIONS).map((mention, key) => (
      <ExternalUrl key={key} url={MENTIONS[mention]} children={LogoView("v2/" + mention)} />
    ));

    return <section className="wr-slider-logos">
      <Slider {...sliderSettings}>
        {links}
      </Slider>
    </section>;
  }
}
