import React from "react";
import i18n from "../i18n";
import routes from "../routes";
import {Link} from "react-router-dom";
import {PHOTO_LAB_LINK, APPSTORE_LINK, PLAY_MARKET_LINK} from "../utils/constants";
import {webviewOpenBrowser} from "../utils/webview";
import {ExternalUrl} from "../pages/IndexPage";
import {SvgSprite} from "../components/SvgSprite";
import {logEvent, userEvents} from "../utils/log";

export default class HeaderClassic extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isDropdownOpen: false,
    };
  }

  handleDropdownClick = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  };

  handlePhotoLabClick = (e) => {
    if (window.clientConfig.isWebview) {
      e.preventDefault();
      webviewOpenBrowser(PHOTO_LAB_LINK);
    }
  };

  render() {
    if (this.props.hidden) {
      return null;
    }

    return <header className="header">
      <div className="header-main-content">
        <Link to={routes.INDEX} className="logo">toonme.com</Link>
        <ExternalUrl url={PHOTO_LAB_LINK} children={i18n.t("btn_photo_lab")} className="btn-photo-lab" onClick={this.handlePhotoLabClick} />
      </div>
      <div className="header-other-content">
        <a href="mailto:contact@toonme.com"
          className="btn-contacts-form"
          dangerouslySetInnerHTML={{__html: i18n.t("btn_contacts_form")}} />
        <div className="app-links-container">
          <button 
            className={"btn-drop" + (this.state.isDropdownOpen ? " active" : "")}
            onClick={this.handleDropdownClick}>
            <span dangerouslySetInnerHTML={{__html: i18n.t("landing__get_the_app_button")}} />
            <SvgSprite viewBox="0 0 24 24" icon="icon-dropdown" />
          </button> 

          <div className={"app-links" + (this.state.isDropdownOpen ? " open" : "")}>
            <ExternalUrl className="btn-drop-store" url={APPSTORE_LINK} onClick={() => logEvent(userEvents.GET_APP_CLICK, {type: "header_v2"})}>
              <SvgSprite viewBox="0 0 66 80" icon="icon-ios-store" />
              <span className="btn-drop-store-content" dangerouslySetInnerHTML={{__html: i18n.t("btn_store__ios")}} />
            </ExternalUrl>
            <ExternalUrl className="btn-drop-store" url={PLAY_MARKET_LINK} onClick={() => logEvent(userEvents.GET_APP_CLICK, {type: "header_v2"})}>
              <SvgSprite viewBox="0 0 64 72" icon="icon-android-store" />
              <span className="btn-drop-store-content" dangerouslySetInnerHTML={{__html: i18n.t("btn_store__android")}} />
            </ExternalUrl>
          </div>
        </div>
      </div>
    </header>;
  }
}
