import React from "react";
import PropTypes from "prop-types";
import i18n from "../../i18n";
import {assetUrl} from "../../utils/etc";

function ClassicTab(props) {
  const jpg = [
    assetUrl(`assets/img/bg/img-classic.jpg`) + " 1x",
    assetUrl(`assets/img/bg/img-classic@2x.jpg`) + " 2x",
    assetUrl(`assets/img/bg/img-classic@3x.jpg`) + " 3x"
  ].join(", ");

  const webp = [
    assetUrl(`assets/img/bg/img-classic.webp`) + " 1x",
    assetUrl(`assets/img/bg/img-classic@2x.webp`) + " 2x",
    assetUrl(`assets/img/bg/img-classic@3x.webp`) + " 3x"
  ].join(", ");

  return <div className="tab-custom tab-classic container">
    <div className="bg-image-container">
      <picture>
        <source srcSet={webp} type="image/webp" />
        <img srcSet={jpg} alt="Demo" />
      </picture>
    </div>
    <div className="tab-lab-content">
      <p dangerouslySetInnerHTML={{__html: i18n.t("result_love__tab_classic_info")}} />
      <button
        dangerouslySetInnerHTML={{__html: i18n.t("result__tab_classic_btn")}}
        onClick={props.onButtonClick}
      />
    </div>
  </div>;
}

ClassicTab.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
};

export default ClassicTab;
