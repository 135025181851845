export const PHOTO_LAB_LINK = "https://pho.to/photolab";

export const PLAY_MARKET_LINK = "https://play.google.com/store/apps/details?id=com.vicman.toonmeapp&referrer=apptoonmecom";
export const APPSTORE_LINK = "https://apps.apple.com/us/app/toonme-cartoon-photo-editor/id1508120751";

export const VC_RU_LINK = "https://vc.ru/u/698899-toonme/201038";

export const CONTACT_US_LINK = "mailto:contact@toonme.com";

export const MENTIONS = {
  "sunmasthead": "https://www.thesun.co.uk/tvandshowbiz/13781577/famous-faces-cartoons-toonme-app/",
  "inquirerdotnet-2016": "https://technology.inquirer.net/107388/toonme-is-making-waves-on-twitter-how-wary-should-we-be",
  "this-morning-tv-logo": "https://www.instagram.com/p/CKOf-S3Hund/?utm_source=ig_web_copy_lin",
  "boredpanda": "https://www.boredpanda.com/toonme-celebrities/",
  "techtudo": "https://www.techtudo.com.br/tudo-sobre/toonme.html",
  "the-star": "https://www.thestar.com.my/tech/tech-news/2021/01/21/toonme-is-making-waves-on-twitter---how-wary-should-we-be",
  "ntv": "https://www.ntv.co.jp/sukkiri/",
  "msn": "https://www.msn.com/en-ie/entertainment/news/donald-trump-kim-kardashian-and-adele-what-would-your-favourite-stars-look-like-as-cartoon-characters/ss-BB1cXHS3#image=3",
  "logo": "https://www.ladepeche.fr/2021/01/20/doit-on-se-mefier-de-toonme-lapplication-qui-fait-un-carton-sur-twitter-9322735.php",
  "logo-money": "https://www.money.it/ToonMe-gratis-come-funziona-app-trasforma-in-cartone-animato",
  "didgital-camera": "https://www.digitalcameraworld.com/news/toon-trump-and-biden-face-editing-app-goes-viral-with-disney-style-filters",
  "logo-grazia-full": "https://graziamagazine.ru/stars/disneevskie-princessy-kak-by-vyglyadeli-kim-kardashyan-sestry-dzhenner-i-drugie-mirovye-zvezdy-esli-by-popali-v-nashi-lyubimye-multfilmy/",
  "urgant": "https://www.instagram.com/p/CKjDxj5HTaO/",
  "webtekno": "https://www.webtekno.com/toonme-fotograf-duzenleme-uygulamasi-h105678.html",
  "allocine": "https://www.allocine.fr/diaporamas/cinema/diaporama-18695988/",
  "basic-thinking": "https://www.basicthinking.de/blog/2021/02/05/toonme-app-verwandelt-dich-in-disney-helden/",
  "fuji-television": "https://www.fujitv.com/variety/wide-na-show/",
}

export const INSTAGRAM_MENTIONS = [
  {
    "username": "onechampionship",
    "avatar": "/assets/img/instagram/onechampionship/avatar.jpg",
    "likes": "17K+",
    "url": "https://www.instagram.com/p/CKOBWpEDsZW/",
    "images": [
      "/assets/img/instagram/onechampionship/image1.jpg"
    ],
  },
  {
    "username": "persija",
    "avatar": "/assets/img/instagram/persija/avatar.jpg",
    "likes": "96K+",
    "url": "https://www.instagram.com/p/CKIvGMTDuyk/",
    "images": [
      "/assets/img/instagram/persija/image1.jpg",
      "/assets/img/instagram/persija/image2.jpg",
      "/assets/img/instagram/persija/image3.jpg",
      "/assets/img/instagram/persija/image4.jpg",
      "/assets/img/instagram/persija/image5.jpg",
      "/assets/img/instagram/persija/image6.jpg",
      "/assets/img/instagram/persija/image7.jpg",
      "/assets/img/instagram/persija/image8.jpg",
      "/assets/img/instagram/persija/image9.jpg",
      "/assets/img/instagram/persija/image10.jpg"
    ],
  },
  {
    "username": "vecherniy_urgant",
    "avatar": "/assets/img/instagram/vecherniy_urgant/avatar.jpg",
    "likes": "44K+",
    "url": "https://www.instagram.com/p/CKjDxj5HTaO/",
    "images": [
      "/assets/img/instagram/vecherniy_urgant/image1.jpg",
      "/assets/img/instagram/vecherniy_urgant/image2.jpg",
      "/assets/img/instagram/vecherniy_urgant/image3.jpg",
      "/assets/img/instagram/vecherniy_urgant/image4.jpg",
      "/assets/img/instagram/vecherniy_urgant/image5.jpg",
      "/assets/img/instagram/vecherniy_urgant/image6.jpg",
      "/assets/img/instagram/vecherniy_urgant/image7.jpg",
      "/assets/img/instagram/vecherniy_urgant/image8.jpg"
    ],
  },
  {
    "username": "lolywoodoff",
    "avatar": "/assets/img/instagram/lolywoodoff/avatar.jpg",
    "likes": "33K+",
    "url": "https://www.instagram.com/p/CJ_j7slr9jP/",
    "images": [
      "/assets/img/instagram/lolywoodoff/image1.jpg",
      "/assets/img/instagram/lolywoodoff/image2.jpg",
      "/assets/img/instagram/lolywoodoff/image3.jpg"
    ],
  },
  {
    "username": "gazette_du_sorcier",
    "avatar": "/assets/img/instagram/gazette_du_sorcier/avatar.jpg",
    "likes": "1K+",
    "url": "https://www.instagram.com/p/CKZV4XrgT21/",
    "images": [
      "/assets/img/instagram/gazette_du_sorcier/image1.jpg",
      "/assets/img/instagram/gazette_du_sorcier/image2.jpg",
      "/assets/img/instagram/gazette_du_sorcier/image3.jpg",
      "/assets/img/instagram/gazette_du_sorcier/image4.jpg",
      "/assets/img/instagram/gazette_du_sorcier/image5.jpg",
      "/assets/img/instagram/gazette_du_sorcier/image6.jpg"
    ],
  },
  {
    "username": "mothershipsg",
    "avatar": "/assets/img/instagram/mothershipsg/avatar.jpg",
    "likes": "36K+",
    "url": "https://www.instagram.com/p/CKDNvNaHjIg/",
    "images": [
      "/assets/img/instagram/mothershipsg/image1.jpg",
      "/assets/img/instagram/mothershipsg/image2.jpg",
      "/assets/img/instagram/mothershipsg/image3.jpg",
      "/assets/img/instagram/mothershipsg/image4.jpg",
      "/assets/img/instagram/mothershipsg/image5.jpg",
      "/assets/img/instagram/mothershipsg/image6.jpg"
    ],
  },
  {
    "username": "thismorning",
    "avatar": "/assets/img/instagram/thismorning/avatar.jpg",
    "likes": "22K+",
    "url": "https://www.instagram.com/p/CKOf-S3Hund/",
    "images": [
      "/assets/img/instagram/thismorning/image1.jpg",
      "/assets/img/instagram/thismorning/image2.jpg"
    ],
  },
  {
    "username": "rompipallone.it",
    "avatar": "/assets/img/instagram/rompipallone/avatar.jpg",
    "likes": "16K+",
    "url": "https://www.instagram.com/p/CJ6SPleAZFT/",
    "images": [
      "/assets/img/instagram/rompipallone/image1.jpg",
      "/assets/img/instagram/rompipallone/image2.jpg",
      "/assets/img/instagram/rompipallone/image3.jpg",
      "/assets/img/instagram/rompipallone/image4.jpg",
      "/assets/img/instagram/rompipallone/image5.jpg",
      "/assets/img/instagram/rompipallone/image6.jpg",
      "/assets/img/instagram/rompipallone/image7.jpg",
      "/assets/img/instagram/rompipallone/image8.jpg",
      "/assets/img/instagram/rompipallone/image9.jpg",
      "/assets/img/instagram/rompipallone/image10.jpg"
    ],
  },
  {
    "username": "giorgiovanniofficial",
    "avatar": "/assets/img/instagram/giorgiovanniofficial/avatar.jpg",
    "likes": "11K+",
    "url": "https://www.instagram.com/p/CJ8-96BnF4t/",
    "images": [
      "/assets/img/instagram/giorgiovanniofficial/image1.jpg"
    ],
  },
];
