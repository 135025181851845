import React from 'react';
import i18n from "../i18n";
import routes from "../routes";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import AppContext from "../contexts/AppContext";
import {prefixRouteForSite} from "../utils/etc";
import ErrorPageViewV1 from "./ErrorPageViewV1";
import ErrorPageViewV2 from "./ErrorPageViewV2";

export default class ErrorPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      message: (props.location.state && props.location.state.message) || undefined,
      photolabCode: (props.location.state && props.location.state.photolabCode) || 0,
    };
  }

  componentDidMount() {
    if (this.state.message === i18n.t("internal_error")
      || (this.state.message && this.state.message.toLowerCase() === "internal error")) {
      hitEvent(hits.INTERNAL_ERROR_VIEW);
    }
  }

  handleFileSelected = (file) => {
    hitEvent(hits.PHOTO_SELECT);
    logEvent(userEvents.PHOTO_SELECT, {page: "error"});

    this.props.history.replace(prefixRouteForSite(routes.CREATE, this.props.site), {file});
  };

  render() {
    let errorMessage = this.state.message
      ? this.state.message
      : i18n.t(window.clientConfig.isWeb ? "error_overload_web" : "error_overload_webview");

    const isOverloadPhotolab = this.state.photolabCode == -5; // !!! не ставить ===
    if (isOverloadPhotolab) {
      hitEvent(hits.ERROR_PAGE_PHOTOLAB_OVERLOAD_MESSAGE);
      errorMessage = i18n.t(window.clientConfig.isWeb ? "error_overload_web" : "error_overload_webview");
    }

    const data = {
      errorMessage,
      isOverloadPhotolab,
      handleFileSelected: this.handleFileSelected
    };

    return window.appConfig.designVersion === "v2"
      ? <ErrorPageViewV2 {...data} />
      : <ErrorPageViewV1 {...data} />;
  }
}

ErrorPage.contextType = AppContext;
