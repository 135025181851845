import React from 'react';
import AppContext from "../contexts/AppContext";
import {Link} from "react-router-dom";
import routes from "../routes";

export default class PrivacyPage extends React.Component {

  render() {

    return <div className="privacy__wrapper">
      <h1><Link to={routes.INDEX} className="privacy__logo">TOONME.COM</Link></h1>

      <p className="privacy__page_title">PRIVACY POLICY</p>
      <p className="privacy__paragraph">
        This Privacy Policy
        describes how TOONME ("TOONME", "We", "Us", or "Our") protects your privacy when you use TOONME services
        ("Services") which includes the Web site (the "Site") and all TOONME mobile phone applications ("Mobile").
      </p>
      <p className="privacy__paragraph">
        PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY ACCESSING OR USING THE
        SERVICE, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS DESCRIBED HEREIN AND ALL TERMS AND CONDITIONS
        INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS SET FORTH BELOW, YOU MAY
        NOT USE THE TOONME SERVICES.
      </p>
      <p className="privacy__paragraph">
        This Privacy Policy applies to information (including personal information)
        collected during your use of TOONME services. For the purpose of this Privacy Policy, "personal information"
        means information that identifies you personally, either alone or in combination with other information
        available to us. Personal information does not include technical, device, or usage information that cannot
        be used to identify you personally, nor does it include "aggregate" information, which is data we collect
        about the use of the Services or about a group or category of Services or users, from which individual
        identities or other personal information have been removed. This Privacy Policy in no way restricts or
        limits our collection and use of aggregate and non-personal information, and we may share such data about
        our users with third parties for various purposes.
      </p>
      <p className="privacy__paragraph">
        <span className="privacy__title">WHAT INFORMATION WE COLLECT</span>
        We respect the privacy of every individual who uses our Services. We collect certain personal information that
        you voluntarily provide to us. For example, we collect your name, email address, user name, social network
        information and other information you provide us when you register, set up an account, contact us by e-mail
        or use our services. We may also obtain information from other companies and combine that with information
        we collect on the Services; for example, when you link your account with Facebook, we might have access to
        your name and profile picture.
      </p>
      <p className="privacy__paragraph">
        When you visit the Site, we automatically collect some information. Such
        information includes the type of browser you use, your browser’s language, access time or the website from
        which you linked to us. We also collect information about your computer's operating system and information
        about your usage and activity on the Site. This information is only used to assist us in providing an
        effective service. We may, from time to time, supply the owners and operators of third party websites and
        mobile applications which have a link to our site or application with information about the number of users
        of our Site or Mobile. You cannot be identified from any of this information.
      </p>
      <p className="privacy__paragraph">
        When you visit the Site, we automatically collect some information. Such
        information includes the type of browser you use, your browser’s language, access time or the website from
        which you linked to us. We also collect information about your computer's operating system and information
        about your usage and activity on the Site. This information is only used to assist us in providing an
        effective service. We may, from time to time, supply the owners and operators of third party websites and
        mobile applications which have a link to our site or application with information about the number of users
        of our Site or Mobile. You cannot be identified from any of this information.
      </p>
      <p className="privacy__paragraph">
        We may also collect information about others if you use our Services to
        upload, share and/or distribute Content that contains information about them, including their name, image
        and online contact information.
      </p>
      <p className="privacy__paragraph">
        <span className="privacy__title">SERVICE DATA</span>
        To use our Services, you
        should provide a photo prior to the event of photo editing. Since the processing requires Internet
        connection and is performed on our servers the photos are sent there through the encrypted connection. We
        use Secure Socket Layer technology to protect the privacy and integrity of the transmission process.
      </p>
      <p className="privacy__paragraph">
        For non-registered users and users who don’t share their results within the
        Services, the original photos and results are automatically removed from our servers by two weeks after the
        last interaction. For registered users who share their results within the Services that provide special
        social network features, the shared content will be stored on the servers and shown within the Services
        unless a user either removes the images themselves or requests such a removal by contacting our support
        team.
      </p>
      <p className="privacy__paragraph">
        Whenever you choose an effect that involves face manipulations we use
        special face recognition technologies to:
      </p>
      <ul className="privacy__list">
        <li>detect a face in a photo;</li>
        <li>find required facial key points (the set of points varies with the effect);</li>
        <li>and apply the effect to your photo.</li>
      </ul>
      <p className="privacy__paragraph">
        The detected key points may be kept along with the photo on the servers of
        our providers for up to two weeks from the last interaction with the photo. The only purpose of this is to
        speed up any further processings of the same photos.
      </p>
      <p className="privacy__paragraph">
        <span className="privacy__title">COOKIES</span>
        We automatically collect
        certain information through the use of "cookies". Cookies are small data files that are stored on your hard
        drive by a Web site. We use cookies to collect information about your device, including where available your
        IP address, operating system and browser type, for system administration and to report information to our
        branded partners.
      </p>
      <p className="privacy__paragraph">
        We may store some information on your computer when you visit the website.
        This information facilitates your use of the website, personalizes your experience and helps us to remember
        your preferences. The information stored in a cookie provides features that recognize your account as logged
        on and other Site features. We do not have any control over cookies which our advertisers or branded
        partners may use on the website.
      </p>
      <p className="privacy__paragraph">
        <span className="privacy__title">SECURITY</span>
        The technology that we use
        and the policies that we have implemented are intended to safeguard your privacy from unauthorized access
        and improper use. We will continue to update these measures as new technology becomes available. However, we
        cannot guarantee that unauthorized third parties will not be able to defeat our security measures. If you
        use a password on our Services, you are responsible for keeping it confidential. Do not share it with any
        other person. If you believe your password has been misused or your account compromised, please advise us
        immediately.
      </p>
      <p className="privacy__paragraph">
        <span className="privacy__title">HOW WE USE YOUR PERSONAL INFORMATION</span>
        We use personal information collected through the Services:
      </p>
      <ul className="privacy__list">
        <li>to communicate with you or third parties;</li>
        <li>to process your requests and transactions;</li>
        <li>to improve the Services;</li>
        <li>to customize the services and/or products we provide to you;</li>
        <li>to assist with our product and service development;</li>
        <li>to perform marketing activities;</li>
        <li>to provide relevant advertising;</li>
        <li>and for other purposes related to our business.</li>
      </ul>
      <p className="privacy__paragraph">
        <span className="privacy__title">WITH WHOM WE SHARE YOUR PERSONAL INFORMATION</span>
        Personal
        information (and non-personal information) collected through the Services may be shared with companies and
        organizations that perform services on our behalf (for example, companies that provide data management or
        other support services to us such as data storage and Web hosting services). We may share your personal
        information (and non-personal information) with third parties to serve you relevant advertising
        (<a href="https://support.google.com/admob/answer/9012903?hl=en&amp;ref_topic=2745287#" target="_blank" rel="noopener noreferrer">learn how advertising partners collect and use data</a>)
        and market our products, but we won't sell your personal information.
      </p>
      <p className="privacy__paragraph">
        Also, we may disclose the personal information we collect through the
        Services when we, in good faith, believe disclosure is appropriate to:
      </p>
      <ul className="privacy__list">
        <li>comply with applicable law (e.g., in response to a valid court order or subpoena);</li>
        <li>prevent or investigate a possible crime, such as fraud or identity theft;</li>
        <li>enforce or apply our Terms of Service and other agreements;</li>
        <li>protect the rights, property or safety of us, our users or others;</li>
        <li>protect your vital interests.</li>
      </ul>
      <p className="privacy__paragraph">
        We do not disclose your images, which you have uploaded for editing and
        manipulation to any of the Services unless you provide explicit consent to show your images at our Site,
        Mobile and other Services, or at third party services.
      </p>
      <p className="privacy__paragraph">
        Your personal information and other data collected by us may be transferred
        to another company that has acquired our stock or assets, for example, as a result of a sale, merger,
        reorganization, dissolution or liquidation. If such a transfer occurs, the acquiring company's use of your
        personal information will still be subject to this Privacy Policy.
      </p>
      <p className="privacy__paragraph">
        <span className="privacy__title">MODIFYING YOUR INFORMATION — OPT-OUT/OPT-IN</span>
        We respect your right to make
        choices about the ways we collect, use and disclose your personal information. You may update or modify your
        information or change your privacy preferences at any time via our Services. You may opt out of receiving
        promotional communications from us, including, but not limited to, promotional emails, by clicking a special
        unsubscribe link in the e-mail or other Content from us. Transactional service communications (such as
        messages about your registration) are not subject to the foregoing opt-out procedures. Requests to disable
        your account or reset your password should be sent to <a href="mailto:support@pho.to">support@pho.to</a> for
        faster processing.
      </p>
      <p className="privacy__paragraph">
        <span className="privacy__title">LINKS, HOSTED SITES &amp; ADVERTISERS</span>
        We do not have any control over the
        privacy practices of any external websites linked from the Site or Mobile (or any of the websites that are
        hosted on our server and aren't affiliated with us). Therefore you should review their privacy policies
        separately from ours and know that we in no way have any control over their practices.
      </p>
      <p className="privacy__paragraph">
        Third party advertisers may collect certain information that is available to
        them through your interaction with their advertisements. TOONME has no control or knowledge as to their
        practices and it is suggested that you read their privacy policy separately before clicking on any
        advertisements.
      </p>
      <p className="privacy__paragraph">
        <span className="privacy__title">CHANGES TO THIS PRIVACY POLICY</span>
        We may
        change this Privacy Policy from time to time. If we make any changes to this Privacy Policy that we think
        materially alter your rights, then we will post the latest policy to this site. We encourage you to review
        this Privacy Policy whenever you visit the Services to understand how your personal information is used.
      </p>
      <p className="privacy__paragraph">
        <span className="privacy__title">QUESTIONS</span>
        If you have any questions about this Privacy Policy, please contact us
        via <a href="mailto:support@pho.to">support@pho.to</a>
      </p>

    </div>;
  }
}

PrivacyPage.contextType = AppContext;
