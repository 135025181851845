import React from 'react';
import i18n from "../../i18n";
import FileChooseButton from "../../components/FileChooseButton";
import HeaderClassic from "../../components/HeaderClassic";
import HeaderLabs from "../../components/HeaderLabs";
import {SvgSprite} from "../../components/SvgSprite";
import AppContext from "../../contexts/AppContext";
import * as creativeUtils from "../../utils/creative";
import ResultPage from "../result/ResultPage";
import SimpleTab from "./SimpleTab";
import VectorTab from "./VectorTab";
import LabsTab from "./LabsTab";
import ClassicTab from "../result/ClassicTab";
import DownloadModal from "./DownloadModal";
import RemoveLogoModal from "./RemoveLogoModal";
import {when} from "../../utils/etc";

export default class ResultPageV2 extends ResultPage {

  showDownloadModal = (creative, withHD) => {
    this.context.pushModal(<DownloadModal
      key="ResultPageV2-DownloadModal"
      site={this.props.site}
      history={this.props.history}
      tab={this.state.tab}
      photo={this.state.photo}
      creative={creative}
      withHD={withHD}
      downloadHandler={this.handleDownload}
      shareHandler={this.handleShare}
    />);
  };

  showRemoveLogoModal = () => {
    this.context.pushModal(<RemoveLogoModal
      key="ResultPageV2-RemoveLogoModal"
      onShowButtonClick={this.handleShowWatermak}
      onHideButtonClick={this.handleHideWatermak}
    />);
  };

  renderTabContainer = (commonTabSettings) => {
    commonTabSettings.group = this.state.tab;

    switch (this.state.tab) {
      case creativeUtils.creativeGroups.BARBIE:
        return <SimpleTab {...commonTabSettings} canBeRefreshed={false} />;
      case creativeUtils.creativeGroups.CARTOON:
        return <SimpleTab {...commonTabSettings} />;
      case creativeUtils.creativeGroups.CARTOON_ANIM:
        return <SimpleTab {...commonTabSettings} canBeRefreshed={false} />;
      case creativeUtils.creativeGroups.T4532:
        return <SimpleTab {...commonTabSettings} />;
      case creativeUtils.creativeGroups.CARICATURE:
      case creativeUtils.creativeGroups.CARICATURE_2:
      case creativeUtils.creativeGroups.CARICATURE_3:
        return <SimpleTab {...commonTabSettings} withHD={this.state.tab === creativeUtils.creativeGroups.CARICATURE_3} />;
      case creativeUtils.creativeGroups.SIMPSONS:
        return <SimpleTab {...commonTabSettings} />;
      case creativeUtils.creativeGroups.CUPID21_1:
        return <SimpleTab {...commonTabSettings} canBeRefreshed={false} />;
      case creativeUtils.creativeGroups.CUPID21_2:
        return <SimpleTab {...commonTabSettings} />;
      case creativeUtils.creativeGroups.DISNEY:
        return <SimpleTab {...commonTabSettings} withHD={true} />;
      case creativeUtils.creativeGroups.DISNEY_2D:
        return <SimpleTab {...commonTabSettings} withHD={true} />;
      case creativeUtils.creativeGroups.ARCHER:
        return <SimpleTab {...commonTabSettings} withHD={true} />;
      case creativeUtils.creativeGroups.VECTOR:
        return <VectorTab {...commonTabSettings} withHD={true} onTemplateSelect={this.handleVectorRefreshButtonClick} />;
      case creativeUtils.creativeGroups.COLLAGE:
        return <SimpleTab {...commonTabSettings} canBeRefreshed={false} onRefreshButtonClick={this.handleRefreshCollageButtonClick} />;
      case "labs":
        return <LabsTab onButtonClick={this.handleLabsButtonClick} />;
      case "classic":
        return <ClassicTab onButtonClick={this.handleClassicButtonClick} />;
      default: {
        return <div />;
      }
    }
  };

  renderView = ({isOwner, isGuest, commonTabSettings, showTabs}) => {
    const Header = when(this.props.site, {
      "classic": HeaderClassic,
      "labs": HeaderLabs,
    });

    return <main className={"collage-page" + (this.props.site === "labs" ? " labs-container" : "")}>
      <Header hidden={window.clientConfig.isWebview} />
      <div className="collage-page-content container">
        <div className="tabs-container">
          {showTabs.map((group) => this.renderTab(group))}
        </div>
        {this.renderTabContainer(commonTabSettings)}
      </div>

      <div className="container " hidden={isOwner}>
        <FileChooseButton
          onFileSelected={this.handleFileSelected}
          className="btn-guest">
            <SvgSprite viewBox="0 0 24 24" icon="icon-magic-guest" />
            <span children={i18n.t("collage__select_photo")} />
        </FileChooseButton>
      </div>
      <SvgSprite className="line-result" viewBox="0 0 1197 734" icon="line-result" />
    </main>;
  };
}

ResultPageV2.contextType = AppContext;
