import moment from "moment";
import deLocale from "moment/locale/de";
import esLocale from "moment/locale/es";
import frLocale from "moment/locale/fr";
import hiLocale from "moment/locale/hi";
import itLocale from "moment/locale/it";
import jaLocale from "moment/locale/ja";
import koLocale from "moment/locale/ko";
import ptLocale from "moment/locale/pt";
import ruLocale from "moment/locale/ru";
import zhLocale from "moment/locale/zh-cn";
import thLocale from "moment/locale/th";

import i18n from "../i18n";

const resources = {
  de: deLocale,
  en: {},
  es: esLocale,
  fr: frLocale,
  hi: hiLocale,
  it: itLocale,
  ja: jaLocale,
  ko: koLocale,
  pt: ptLocale,
  ru: ruLocale,
  th: thLocale,
  zh: zhLocale,
};

export function formatClassicUpdatedAt() {
  let lang = window.clientConfig.lang.substring(0, 2).toLowerCase();
  lang = i18n.exists("updated_at", {lng: lang, fallbackLng: lang}) ? lang : "en";

  const updatedAt = moment(process.env.REACT_APP_UPDATED_AT_CLASSIC);

  const format = {
    de: "D. MMMM YYYY",
    en: "MMMM D, YYYY",
    es: "D [de] MMMM [de] YYYY",
    fr: "D MMMM YYYY",
    hi: "D MMMM, YYYY",
    it: "D MMMM YYYY",
    ja: "YYYY年M月D日",
    ko: "YYYY년 MMMM D일",
    pt: "D [de] MMMM [de] YYYY",
    ru: "D MMMM YYYY года",
    th: "D MMM YYYY",
    zh: "YYYY年M月D日",
  };

  return updatedAt.locale(lang, resources[lang]).format(format[lang]);
}

export function formatLabsUpdatedAt() {
  let lang = window.clientConfig.lang.substring(0, 2).toLowerCase();
  lang = i18n.exists("updated_at_labs", {lng: lang, fallbackLng: lang}) ? lang : "en";

  const updatedAt = moment(process.env.REACT_APP_UPDATED_AT_LABS);

  const format = {
    en: "MMM D, YYYY",
  };

  return updatedAt.locale(lang, resources[lang]).format(format[lang]);
}