const VERSION = 2;

const KEY_PREFS = "tmc:prefs";
const KEY_PREFS_VERSION = "tmc:prefs:version";

const KEY_CLIENT_TOKEN = "client_token";
const KEY_PRO_WATERMARK_REMOVE_CHOICE_AT = "pro_watermark_remove_choice_at";
const KEY_PRO_WATERMARK_TOOLTIP_IS_HIDDEN = "pro_watermark_tooltip_is_hidden";
const KEY_PRO_WATERMARK_SHOULD_BE_REMOVED = "pro_watermark_should_be_removed";
const KEY_BEFORE_PHOTO_SHOULD_BE_REMOVED = "before_photo_should_be_removed";
const KEY_SPLIT_GROUP_ID = "split_group_id";
const KEY_HAS_PROCESSED_PROCESSING = "has_processed_processings";
const KEY_PROCESSINGS_AMOUNT = "processings_amount";
const KEY_SPLIT_INDEX_IMAGES = "split__index_images";
const KEY_RATEAPP_IS_RATE = "rateapp_request__rate";
const KEY_RATEAPP_IS_SKIP = "rateapp_request__skip";
const KEY_RATEAPP_LAST_SHOW = "rateapp_request__last_show";
const KEY_CLONES_DIALOG_IS_ACCEPT = "clones_dialog__accept";
const KEY_CLONES_DIALOG_IS_SKIP = "clones_dialog__skip";
const KEY_CLONES_DIALOG_LAST_SHOW = "clones_dialog__last_show";
const KEY_HIRING_JAPAN_SHOWN_AMOUNT = "hiring_japan_shown_amount";

class ClientStorage {

  constructor() {
    const prefs = window.localStorage.getItem(KEY_PREFS);
    if (prefs) {
      this.prefs = JSON.parse(prefs);
    } else {
      this.prefs = {};
    }

    const currentVersion = parseInt(window.localStorage.getItem(KEY_PREFS_VERSION) || 0);
    if (currentVersion !== VERSION) {
      this.migrate(currentVersion, VERSION);
      window.localStorage.setItem(KEY_PREFS_VERSION, VERSION);
    }
  }

  migrate(fromVersion, toVersion) {
    if (toVersion === 2) {
      this.prefs[KEY_CLIENT_TOKEN] = window.localStorage.getItem(KEY_CLIENT_TOKEN) || undefined;
      this.prefs[KEY_PRO_WATERMARK_REMOVE_CHOICE_AT] = window.localStorage.getItem(KEY_PRO_WATERMARK_REMOVE_CHOICE_AT) || undefined;
      this.prefs[KEY_PRO_WATERMARK_TOOLTIP_IS_HIDDEN] = window.localStorage.getItem(KEY_PRO_WATERMARK_TOOLTIP_IS_HIDDEN) === "1" || undefined;
      this.prefs[KEY_PRO_WATERMARK_SHOULD_BE_REMOVED] = window.localStorage.getItem(KEY_PRO_WATERMARK_SHOULD_BE_REMOVED) === "1" || undefined;
      this.prefs[KEY_SPLIT_GROUP_ID] = window.localStorage.getItem(KEY_SPLIT_GROUP_ID) || undefined;
      this.prefs[KEY_HAS_PROCESSED_PROCESSING] = window.localStorage.getItem(KEY_HAS_PROCESSED_PROCESSING) === "1" || undefined;
      this.prefs[KEY_PROCESSINGS_AMOUNT] = window.localStorage.getItem(KEY_PROCESSINGS_AMOUNT) || undefined;
      this.prefs[KEY_SPLIT_INDEX_IMAGES] = window.localStorage.getItem(KEY_SPLIT_INDEX_IMAGES) || undefined;
      this.prefs[KEY_RATEAPP_IS_RATE] = window.localStorage.getItem(KEY_RATEAPP_IS_RATE) === "1" || undefined;
      this.prefs[KEY_RATEAPP_IS_SKIP] = window.localStorage.getItem(KEY_RATEAPP_IS_SKIP) === "1" || undefined;
      this.prefs[KEY_RATEAPP_LAST_SHOW] = window.localStorage.getItem(KEY_RATEAPP_LAST_SHOW) || undefined;
      this.prefs[KEY_CLONES_DIALOG_IS_ACCEPT] = window.localStorage.getItem(KEY_CLONES_DIALOG_IS_ACCEPT) === "1" || undefined;
      this.prefs[KEY_CLONES_DIALOG_IS_SKIP] = window.localStorage.getItem(KEY_CLONES_DIALOG_IS_SKIP) === "1" || undefined;
      this.prefs[KEY_CLONES_DIALOG_LAST_SHOW] = window.localStorage.getItem(KEY_CLONES_DIALOG_LAST_SHOW) || undefined;
      this.commit();
    }
  }

  commit() {
    window.localStorage.setItem(KEY_PREFS, JSON.stringify(this.prefs));
  }

  setBoolean(key, value) {
    this.prefs[key] = !!value;
    this.commit();
  }

  getBoolean(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return !!this.prefs[key];
    } else {
      return defaultValue;
    }
  }

  setInteger(key, value) {
    this.prefs[key] = parseInt(value);
    this.commit();
  }

  getInteger(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return parseInt(this.prefs[key]);
    } else {
      return defaultValue;
    }
  }

  setString(key, value) {
    this.prefs[key] = "" + value;
    this.commit();
  }

  getString(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return "" + this.prefs[key];
    } else {
      return defaultValue;
    }
  }

  // ---

  getClientToken() {
    return this.getString(KEY_CLIENT_TOKEN);
  }

  setClientToken(value) {
    this.setString(KEY_CLIENT_TOKEN, value);
  }

  getProWatermarkTooltipIsHidden() {
    return this.getBoolean(KEY_PRO_WATERMARK_TOOLTIP_IS_HIDDEN, false);
  }

  setProWatermarkTooltipIsHidden(value) {
    this.setBoolean(KEY_PRO_WATERMARK_TOOLTIP_IS_HIDDEN, value);
  }

  getProWatermarkRemoveChoiceAt() {
    return this.getInteger(KEY_PRO_WATERMARK_REMOVE_CHOICE_AT, 0);
  }

  setProWatermarkRemoveChoiceAt(value) {
    this.setInteger(KEY_PRO_WATERMARK_REMOVE_CHOICE_AT, value);
  }

  getProWatermarkShouldBeRemoved() {
    return this.getBoolean(KEY_PRO_WATERMARK_SHOULD_BE_REMOVED, false);
  }

  setProWatermarkShouldBeRemoved(value) {
    this.setBoolean(KEY_PRO_WATERMARK_SHOULD_BE_REMOVED, value);
  }

  getBeforePhotoShouldBeRemoved() {
    return this.getBoolean(KEY_BEFORE_PHOTO_SHOULD_BE_REMOVED, false);
  }

  setBeforePhotoShouldBeRemoved(value) {
    this.setBoolean(KEY_BEFORE_PHOTO_SHOULD_BE_REMOVED, value);
  }

  getHasProcessedProcessing() {
    return this.getBoolean(KEY_HAS_PROCESSED_PROCESSING, false);
  }

  setHasProcessedProcessing(value) {
    this.setBoolean(KEY_HAS_PROCESSED_PROCESSING, value);
  }

  getProcessingsAmount() {
    return this.getInteger(KEY_PROCESSINGS_AMOUNT, 0);
  }

  incrementProcessingsAmount() {
    const current = this.getInteger(KEY_PROCESSINGS_AMOUNT, 0);
    const next = current + 1;

    this.setInteger(KEY_PROCESSINGS_AMOUNT, next);
    
    return next;
  }

  getSplitGroupId() {
    return this.getInteger(KEY_SPLIT_GROUP_ID, undefined);
  }

  setSplitGroupId(value) {
    this.setInteger(KEY_SPLIT_GROUP_ID, value);
  }

  getSplitIndexImagesGroup() {
    return this.getString(KEY_SPLIT_INDEX_IMAGES, undefined);
  }

  setSplitIndexImagesGroup(value) {
    this.setString(KEY_SPLIT_INDEX_IMAGES, value);
  }

  getRateAppRequestIsRate() {
    return this.getBoolean(KEY_RATEAPP_IS_RATE, false);
  }

  setRateAppRequestIsRate(value) {
    this.setBoolean(KEY_RATEAPP_IS_RATE, value);
  }

  getRateAppRequestIsSkip() {
    return this.getBoolean(KEY_RATEAPP_IS_SKIP, false);
  }

  setRateAppRequestIsSkip(value) {
    this.setBoolean(KEY_RATEAPP_IS_SKIP, value);
  }

  getRateAppRequestLastShow() {
    return this.getInteger(KEY_RATEAPP_LAST_SHOW, 0);
  }

  setRateAppRequestLastShow(value) {
    this.setInteger(KEY_RATEAPP_LAST_SHOW, value);
  }

  getClonesDialogIsAccept() {
    return this.getBoolean(KEY_CLONES_DIALOG_IS_ACCEPT, false);
  }

  setClonesDialogIsAccept(value) {
    this.setBoolean(KEY_CLONES_DIALOG_IS_ACCEPT, value);
  }

  getClonesDialogIsSkip() {
    return this.getBoolean(KEY_CLONES_DIALOG_IS_SKIP, false);
  }

  setClonesDialogIsSkip(value) {
    this.setBoolean(KEY_CLONES_DIALOG_IS_SKIP, value);
  }

  getClonesDialogLastShow() {
    return this.getInteger(KEY_CLONES_DIALOG_LAST_SHOW, 0);
  }

  setClonesDialogLastShow(value) {
    this.setInteger(KEY_CLONES_DIALOG_LAST_SHOW, value);
  }

  getHiringJapanShownAmount() {
    return this.getInteger(KEY_HIRING_JAPAN_SHOWN_AMOUNT, 0);
  }

  incrementHiringJapanShownAmount() {
    const current = this.getInteger(KEY_HIRING_JAPAN_SHOWN_AMOUNT, 0);
    const next = current + 1;

    this.setInteger(KEY_HIRING_JAPAN_SHOWN_AMOUNT, next);

    return next;
  }
}

export default new ClientStorage();